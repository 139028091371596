import { Typography, Grid, Button } from '@mui/material';
import { ArrowLeft, ArrowRight, FastForward, FastRewind } from '@mui/icons-material';
import Moment from 'moment';

interface Props {
  title: string;
  offset?: number;
  displayYear?: boolean;
  forwardLimit?: number;
  yearNav?: boolean;
  stateSetter?(number): void;
}

export function WidgetTitle({ title, offset, displayYear, forwardLimit, yearNav, stateSetter }: Props) {
  const now = Moment(Date.now());
  var date = Moment(now).add(offset, 'months');
  var month = date.format('MMMM');
  var year = date.format('yyyy');

  const canGoBack = (step) => Moment(date).add(step, 'months') > Moment('2020-05-01');

  const canGoForward = (step) => Moment(date).add(step, 'months') < Moment(now).add(forwardLimit ?? 0, 'months');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ display: 'inline-block' }}>
          {title} {month} {displayYear || Moment(date).add(-1, 'months').year() !== now.year() ? year : ''}
        </Typography>
        {offset !== undefined && stateSetter && (
          <div
            style={{
              display: 'inline-block',
              marginRight: -8,
              float: 'right',
            }}>
            {yearNav && (
              <Button
                variant="text"
                title="forrige år"
                sx={{
                  minWidth: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                onClick={() => {
                  stateSetter(-12);
                }}
                disabled={!canGoBack(-12)}>
                <FastRewind sx={{ fontSize: 20 }} />
              </Button>
            )}

            <Button
              variant="text"
              title="forrige måned"
              sx={{
                minWidth: 24,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              onClick={() => {
                stateSetter(-1);
              }}
              disabled={!canGoBack(-1)}>
              <ArrowLeft />
            </Button>

            <Button
              variant="text"
              title="neste måned"
              sx={{
                minWidth: 24,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              onClick={() => {
                stateSetter(+1);
              }}
              disabled={!canGoForward(1)}>
              <ArrowRight />
            </Button>

            {yearNav && (
              <Button
                variant="text"
                title="neste år"
                sx={{
                  minWidth: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                onClick={() => {
                  stateSetter(+12);
                }}
                disabled={!canGoForward(12)}>
                <FastForward sx={{ fontSize: 20 }} />
              </Button>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
}
