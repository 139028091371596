import { createTheme } from '@mui/material/styles';

export default createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#287891',
    },
    secondary: {
      main: '#666666',
    },
    error: {
      main: '#CF453A',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.7rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: '#287891',
          },
        },
      },
    },
  },
});
