import { FC, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UPDATE_PRICE } from './pdf.graphql';
import moment from 'moment';
import { GuidePriceModel } from './overview';
import { DateTimePicker } from '@mui/x-date-pickers';

export const UpdatePriceDialog: FC<{ pdf: GuidePriceModel; onClose(): void; setBusy(busy: boolean): void }> = ({ pdf, onClose, setBusy }) => {
  const defaultTimeOfChange = moment().add(1, 'years').startOf('year');

  const [price, setPrice] = useState(pdf.price);
  const [timeOfChange, setTimeOfChange] = useState(defaultTimeOfChange);

  const [updateValue, { loading }] = useMutation(UPDATE_PRICE, { variables: { input: { price, timeOfChange } } });

  useEffect(() => {
    setBusy(loading);
  }, [loading, setBusy]);

  const priceChanged = price !== pdf.price;

  const onCommit = () => {
    updateValue();
    onClose();
  };

  const close = () => {
    setPrice(pdf.price);
    onClose();
  };

  if (!pdf) return null;

  const onTimeOfChangeChanged = (newValue) => {
    if (newValue) setTimeOfChange(newValue);
    else setTimeOfChange(defaultTimeOfChange);
  };

  return (
    <Dialog onClose={close} aria-labelledby="dialog-title" open>
      <DialogTitle id="dialog-title">
        Endre pris
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={close}
          size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <div
            style={{
              background: 'rgb(232, 244, 253)',
              borderRadius: 4,
              padding: 5,
              marginBottom: 25,
            }}>
            <Typography gutterBottom>Når skal endringen gjelde fra?</Typography>
            <div>
              <DateTimePicker label="tidspunkt" value={timeOfChange} onChange={onTimeOfChangeChanged} />
            </div>
          </div>
          <Grid2 container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid2>
              <TextField
                variant="outlined"
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">kr</InputAdornment>,
                  },
                  htmlInput: { min: 0 },
                }}
                label="Ny pris"
                helperText="Beløp for 1 pdf"
                type="number"
                margin="normal"
                required
                value={price}
                onChange={(ev) => setPrice(Number(ev.target.value))}
              />
            </Grid2>
          </Grid2>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} disabled={!priceChanged} color="primary" variant="contained">
          Endre pris
        </Button>
      </DialogActions>
    </Dialog>
  );
};
