import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  ListItemButton,
} from '@mui/material';
import { Delete, Add, Close } from '@mui/icons-material';
import { MainContentPanel } from '../../components/MainContentPanel';
import LoadingSpinner from '../../components/LoadingSpinner';
import { formatDateOnly } from '../../Formatters';
import { Link } from 'react-router-dom';
import { GET_DISCOUNTS } from '../../discounts/discount.graphql';
import { ADD_DISCOUNT, REMOVE_DISCOUNT } from './Discounts.graphql';
import { GET_LICENSE } from './LicenseInfo.graphql';
import { DiscountOverview } from '../../interfaces';
import { useMutation, useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
}

export function Discounts({ licenseId }: Props) {
  const { loading, data } = useQuery(GET_LICENSE, { variables: { id: licenseId } });
  const [removeDiscount] = useMutation(REMOVE_DISCOUNT);
  const [open, setOpen] = useState(false);

  const handleRemoveDiscount = (discountId: string) => {
    removeDiscount({ variables: { input: { id: licenseId, discountId: discountId } } });
  };

  return (
    <>
      <MainContentPanel>
        <Typography gutterBottom variant="h5">
          Rabatter
          <IconButton aria-label="Legg til rabatt" onClick={() => setOpen(true)} size="large">
            <Add />
          </IconButton>
        </Typography>
        {loading ? <LoadingSpinner /> : <DiscountList license={data.license.license} onRemoveDiscount={handleRemoveDiscount} />}
      </MainContentPanel>
      <DialogAddDiscount licenseId={licenseId} open={open} onClose={() => setOpen(false)} />
    </>
  );
}

function DiscountList({ license, onRemoveDiscount }) {
  const id = '1'; // All discounts
  const { loading, data } = useQuery<{ discounts: DiscountOverview[] }>(GET_DISCOUNTS, { variables: { id } });
  const getDiscountName = (id) => {
    if (loading || !data) return '';
    let discount = data.discounts.find((x) => x.id === id);
    if (!discount) return '';

    return discount.name;
  };
  const getDiscountValidFrom = (id) => {
    if (loading || !data) return '';
    let discount = data.discounts.find((x) => x.id === id);
    if (!discount) return '';

    return discount.validFrom;
  };
  return (
    <List dense>
      {license &&
        license.discounts.map((x) => (
          <ListItem key={x}>
            <ListItemText primary={<Link to={'/discount/' + x}>{getDiscountName(x)}</Link>} secondary={formatDateOnly(getDiscountValidFrom(x))} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => onRemoveDiscount(x)} size="large">
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}

function DialogAddDiscount({ licenseId, open, onClose }) {
  const [selectedDiscountId, setSelectedDiscountId] = useState<string | null>(null);
  const handleClose = () => {
    onClose();
    setSelectedDiscountId(null);
  };
  const [addDiscount] = useMutation(ADD_DISCOUNT);

  const handleAddDiscount = () => {
    addDiscount({ variables: { input: { id: licenseId, discountId: selectedDiscountId } } });
    handleClose();
  };
  const id = '1'; // all discounts
  const { loading, data } = useQuery<{ discounts: DiscountOverview[] }>(GET_DISCOUNTS, { variables: { id } });

  return (
    <Dialog fullWidth maxWidth="sm" scroll="paper" onClose={handleClose} open={open}>
      <DialogTitle>
        Legg til rabatt
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
          size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Velg ønsket rabattkode</DialogContentText>
        <List>
          {!loading && data ? (
            data.discounts
              .filter((x) => x.isApplicable)
              .map((d) => (
                <ListItemButton key={d.id} onClick={() => setSelectedDiscountId(d.id)} selected={selectedDiscountId === d.id}>
                  <ListItemText primary={d.name} secondary={formatDateOnly(d.validFrom)} />
                </ListItemButton>
              ))
          ) : (
            <Typography>Ingen funnet</Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Lukk</Button>
        <Button disabled={selectedDiscountId === null} onClick={handleAddDiscount} color="primary" variant="contained">
          Legg til
        </Button>
      </DialogActions>
    </Dialog>
  );
}
