import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Usage } from '../Usage';

interface DocumentOverviewProps {
  onBackClick: () => void;
}

export default function DocumentOverview(props: DocumentOverviewProps) {
  const { onBackClick } = props;

  return (
    <div style={{ marginBottom: '20px' }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <IconButton onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="black">
            Anvisning liste
          </Typography>
        </Grid>
      </Grid>
      <Usage />
    </div>
  );
}
