import React, { useState, ReactNode } from 'react';
import {
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQuery } from '@apollo/client';
import { GET_COMPANIES } from '../reports.graphql';
import { CompanyOverviewModel, CompanyOverviewProps } from '../../interfaces';

import CompanyDetail from './CompanyDetail';
import { GeneralExcelExport, GeneralExcelExportProps } from '../brreg';

export default function CompanyOverview(props: CompanyOverviewProps) {
  const { onBackClick } = props;
  const [filter, setFilter] = useState('alle');
  const [searchText, setSearchText] = useState('');
  const { loading, data } = useQuery<{ companyOverview: CompanyOverviewModel[] }>(GET_COMPANIES, {
    fetchPolicy: 'cache-and-network',
  });
  const [selectedOverview, setSelectedOverview] = React.useState('companyOverview');
  const [selectedOrgNumber, setSelectedOrgNumber] = React.useState('orgNumber');
  const [selectedCompany, setSelectedCompany] = React.useState('companyName');

  const handleFilterChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = loading
    ? undefined
    : data?.companyOverview.filter((company) => {
        if (filter === 'alle') {
          return true;
        } else if (filter === 'største') {
          return company.licenseCount > 10;
        } else {
          return false;
        }
      });

  const searchedData = filteredData?.filter((company) =>
    Object.values(company).some((value) => value?.toString().toLowerCase().startsWith(searchText.toLowerCase())),
  );

  if (loading) {
    return <CircularProgress />;
  }
  // Define function to handle link clicks
  const handleLinkClick = (overview: string, orgNumber: string, companyName: string) => {
    setSelectedOrgNumber(orgNumber);
    setSelectedOverview(overview);
    setSelectedCompany(companyName);
  };

  const arrHeading: string[] = ['Org. Nummer', 'Firma Navn', 'Brreg', 'License', 'Bruke'];
  const dataSet = filteredData && filteredData.map((x) => [x.orgNumber, x.companyName, x.brreg, x.licenseCount, x.userCount]);
  const workBook = 'KUNDE_LISTE';
  const fileName = 'kunde_liste.xlsx';
  const propsExcel: GeneralExcelExportProps = {
    dataSet: JSON.stringify(dataSet),
    headings: JSON.stringify(arrHeading),
    workBookName: workBook,
    fileName: fileName,
  };

  return (
    <>
      {selectedOverview === 'companyOverview' && (
        <div>
          {' '}
          <Box display="flex" alignItems="center" marginBottom="16px">
            <IconButton onClick={onBackClick}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">Firma liste</Typography>
          </Box>
          <Box
            sx={{
              padding: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              marginBottom: '16px',
              marginLeft: '16px',
              marginRight: '16px',
              display: 'flex',
            }}>
            <Typography
              variant="subtitle1"
              sx={{
                marginRight: '16px',
                paddingTop: '16px',
              }}>
              Filtrere på:
            </Typography>
            <Select
              value={filter}
              onChange={handleFilterChange}
              sx={{
                minWidth: '200px',
                marginRight: '16px',
              }}>
              <MenuItem value="alle">Alle</MenuItem>
              <MenuItem value="største">Største</MenuItem>
            </Select>
            <TextField
              label="Søk"
              value={searchText}
              onChange={handleSearchChange}
              sx={{
                flexGrow: 1,
                marginLeft: '16px',
              }}
            />
            <GeneralExcelExport {...propsExcel} />
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Org. Nummer</TableCell>
                <TableCell>Firma Navn</TableCell>
                <TableCell>Brreg</TableCell>
                <TableCell>License </TableCell>
                <TableCell>Bruker </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedData?.map((company, index) => {
                return (
                  <TableRow
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f5f5f5', // Change the color to whatever you like
                      },
                    }}
                    key={index}
                    onClick={() => handleLinkClick('companyDetail', company.orgNumber, company.companyName)}>
                    <TableCell>{company.orgNumber}</TableCell>
                    <TableCell>{company.companyName}</TableCell>
                    <TableCell>{company.brreg}</TableCell>
                    <TableCell>{company.licenseCount}</TableCell>
                    <TableCell>{company.userCount}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>{' '}
        </div>
      )}
      {selectedOverview === 'companyDetail' && (
        <CompanyDetail onBackClick={() => setSelectedOverview('companyOverview')} orgNumber={selectedOrgNumber} companyName={selectedCompany} />
      )}
    </>
  );
}
