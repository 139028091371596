import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, Paper, IconButton, Box, Grid2, Stack, TableBody } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_TERMINATION } from './reports.graphql';
import LoadingSpinner from '../components/LoadingSpinner';
import XLSX from 'xlsx';
import ExcelLogo from '../assets/microsoft-excel.svg?react';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';

export function TerminationReport() {
  const [from, setFrom] = useState<moment.Moment | null>(moment().startOf('years'));
  const [to, setTo] = useState<moment.Moment | null>(null);
  const { loading, data } = useQuery<{ model: TerminationModel[] }>(GET_TERMINATION, { variables: { fromDate: from, toDate: to } });
  return (
    <Stack spacing={2}>
      <Grid2 container spacing={3} alignItems="center">
        <Grid2>
          <DatePicker disableFuture label="Fra dato" value={from} onChange={setFrom} />
        </Grid2>
        <Grid2>
          <DatePicker disableFuture label="Til dato" value={to} onChange={setTo} />
        </Grid2>
        <Grid2>{data && <TerminationExcelExport model={data.model} />}</Grid2>
      </Grid2>
      {loading && <LoadingSpinner />}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Kunde</TableCell>
            <TableCell>Mottatt</TableCell>
            <TableCell>Årsak</TableCell>
            <TableCell>Abonnementer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.model.map((x) => {
              const arrLength = x.licenseSubscriptions.length;
              return (
                <TableRow key={x.id}>
                  <TableCell
                    sx={{
                      width: 1,
                      whiteSpace: 'nowrap' as 'nowrap',
                    }}>
                    {x.licenseUserName}
                  </TableCell>
                  <TableCell>{x.terminationRequestedDate}</TableCell>
                  <TableCell>{x.terminationReason}</TableCell>
                  <TableCell
                    sx={{
                      width: 1,
                      whiteSpace: 'nowrap' as 'nowrap',
                    }}>
                    {x.licenseSubscriptions.map((sub, i) => {
                      return arrLength === i + 1 ? sub : sub + ', ';
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Stack>
  );
}

function TerminationExcelExport({ model }: { model: TerminationModel[] }) {
  const exportFile = () => {
    const workbook = XLSX.utils.book_new();
    const headingForPending: string[] = ['Kunde', 'Oppsigelse mottatt', 'Årsak', 'Abonnementer'];
    const inputHeading = headingForPending;
    const workBookName = 'OPPSIGELSER';
    const data = model.map((x) => [x.licenseUserName, x.terminationRequestedDate, x.terminationReason, x.licenseSubscriptions]);
    const input = [inputHeading, ...data];
    const sheet = XLSX.utils.aoa_to_sheet(input);
    XLSX.utils.book_append_sheet(workbook, sheet, workBookName);
    XLSX.writeFile(workbook, 'churn.xlsx');
  };
  return (
    <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
      <ExcelLogo
        style={{
          width: 24,
          height: 24,
        }}
      />
    </IconButton>
  );
}

interface TerminationModel {
  licenseUserName: string;
  licenseSubscriptions: string[];
  terminationRequestedDate: string;
  terminationReason: string;
  id: number;
}
