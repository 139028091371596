import { TableCell, TableRow } from '@mui/material';
import { AdfsSetting } from '../../interfaces';
import { Link } from 'react-router-dom';

interface Prop {
  provider: AdfsSetting;
}
export default function Scheme(props: Prop) {
  const { provider } = props;
  // const isExpiryDateDefault=(date)=>{
  //   return (date==='01/01/0001 00:00:00')?true:false;
  // }
  return (
    <>
      <TableRow>
        <TableCell>
          <Link to={'/license/' + provider.bksID} style={{ textDecoration: 'none' }}>
            {provider.scheme}
          </Link>
        </TableCell>
        {/* <TableCell   >
          {isExpiryDateDefault(provider.expiryDate)?"":provider.expiryDate}</TableCell><TableCell align="right" >
        </TableCell> */}
        <TableCell>
          <Link to={'/adfssubsetting/' + provider.bksID} style={{ textDecoration: 'none' }}>
            {provider.extraUserPercentage}{' '}
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
}
