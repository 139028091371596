import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableCell, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { formatDateOnly } from '../../Formatters';
import { LicenseOverviewModel, showColumn } from './ColumnDefinitions';
import { ISettings, SubscriptionName } from './ColumnDefinitions';
import { Info } from '@mui/icons-material';

interface LicenseTableProps {
  items: ISettings[];
  licenses: LicenseOverviewModel[];
  personName?: any;
}

export function LicenseTable({ personName, items, licenses }: LicenseTableProps) {
  const [numberOfItems, setNumberOfItems] = useState(50);

  useEffect(() => {
    setNumberOfItems(50);
  }, [personName]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {items
            .filter((c) => c.showColumn)
            .map((item) => (
              <TableCell style={{ whiteSpace: 'pre-wrap' }} key={item.name}>
                {item.name}
                {item.infoText !== '' && (
                  <Tooltip title={<div style={{ whiteSpace: 'pre-wrap' }}>{item.infoText}</div>}>
                    <Info style={{ fontSize: 14 }} />
                  </Tooltip>
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={licenses.length > numberOfItems}>
        {licenses.slice(0, numberOfItems).map((x) => {
          return (
            <TableRow key={x.id}>
              {showColumn('companyName', items) && (
                <TableCell>
                  <Name license={x} />
                </TableCell>
              )}
              {showColumn('orgNumber', items) && <TableCell>{x.orgNumber}</TableCell>}
              {showColumn('customerNumber', items) && <TableCell>{x.customerNumber}</TableCell>}
              {showColumn('administrator', items) && <TableCell>{x.administrator}</TableCell>}
              {showColumn('createdAt', items) && <TableCell>{formatDateOnly(x.createdAt)}</TableCell>}
              {showColumn('invoiceRef', items) && <TableCell>{x.invoiceRef}</TableCell>}
              {showColumn('address1', items) && <TableCell>{x.address?.address1}</TableCell>}
              {showColumn('subscriptions', items) && <TableCell>{x.subscriptions?.map((c) => SubscriptionName(c)).join(',')}</TableCell>}
              {showColumn('isGodkjentVaatromsbedrift', items) && (
                <TableCell>{x.isGodkjentVaatromsbedrift === null ? 'Mangler data' : x.isGodkjentVaatromsbedrift ? 'Ja' : 'Nei'}</TableCell>
              )}
              {showColumn('usersPerSubscription', items) && (
                <TableCell>{x.usersPerSubscriptions?.map((c) => SubscriptionName(c.subscriptionId) + ': ' + c.numberOfUsers).join(', ')}</TableCell>
              )}
              {showColumn('licensesPerSubscription', items) && (
                <TableCell>{x.licensesPerSubscription?.map((c) => SubscriptionName(c.subscriptionId) + ': ' + c.numberOfUsers).join(', ')}</TableCell>
              )}
              {showColumn('industryCode', items) && <TableCell>{x.code !== null ? x.code + ',' + x.codeName : ''}</TableCell>}
              {showColumn('numberOfEmployees', items) && <TableCell>{x.numberOfEmployees! !== null ? x.numberOfEmployees! : ''}</TableCell>}
              {showColumn('postalcode', items) && <TableCell>{x.address?.postalCode}</TableCell>}
              {showColumn('country', items) && <TableCell>{x.address?.country}</TableCell>}
              {showColumn('totalPageViews', items) && <TableCell>{x.totalPageViews}</TableCell>}
              {showColumn('lockedAt', items) && <TableCell>{x.lockedAt}</TableCell>}
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}
function Name({ license }: { license: LicenseOverviewModel }) {
  return <Link to={'/license/' + license.id}>{license.companyName ? license.companyName : '<Mangler navn>'}</Link>;
}
