import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_ADFS, GET_ADFS } from './ADFSConfig.graphql';
import { Login } from '@mui/icons-material';
import Loading from '../../components/LoadingSpinner';
import { AdfsSetting } from '../../interfaces';
import { Link } from 'react-router-dom';
import { GET_LICENSE } from '../../licenses/details/LicenseInfo.graphql';
import { useQuery } from '@apollo/client';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import ProviderName from './ProviderName';
import EmailDomain from './EmailDomain';
import CallbackPath from './CallbackPath';
import TenantID from './TenantID';
import ClientID from './ClientID';
import ClientSecret from './ClientSecret';

interface Props {
  licenseId: number;
  licenseScheme: string;
}
export default function NewADFSButton({ licenseId, licenseScheme }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Grid container direction="row" alignItems="flex-start">
        {!licenseScheme && (
          <>
            {' '}
            <Grid item xs={12}>
              <IconButton onClick={() => setOpen(true)} title="Bytte til ADFS">
                <Login />
              </IconButton>
            </Grid>
          </>
        )}
        {licenseScheme && (
          <>
            <Grid item xs={10}>
              <Link
                to={'/adfssubsetting/' + licenseId}
                style={{
                  fontSize: 16,
                  fontStyle: 'selectorRoboto',
                  fontWeight: 400,
                  color: '#0000ee',
                }}>
                {licenseScheme}
              </Link>
            </Grid>
          </>
        )}
      </Grid>
      {open && licenseId && <AddADFSConfigDialog licenseId={licenseId} onClose={() => setOpen(false)} />}
    </>
  );
}
function AddADFSConfigDialog({ licenseId, onClose }) {
  const [tenantID, setTenantID] = useState('');
  const [clientID, setClientID] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  const [providerName, setProviderName] = useState('');
  const [callbackPath, setCallbackPath] = useState('');
  const [isErrorOnProviderName, setIsErrorOnProviderName] = useState(false);
  const [isErrorOnEmailDomain, setIsErrorOnEmailDomain] = useState(false);
  const [isErrorOnCallbackPath, setIsErrorOnCallbackPath] = useState(false);

  const handleClose = () => {
    setTenantID('');
    setClientID('');
    setClientSecret('');
    setProviderName('');
    setCallbackPath('');
    onClose();
  };
  const handleCloseBtn = (event, reason) => {
    if (event.disableBackdropClick && reason === 'backdropClick') {
      return false;
    }
    if (event.disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }
    if (typeof onClose === 'function') {
      onClose();
    }
    setTenantID('');
    setClientID('');
    setClientSecret('');
    setProviderName('');
    setCallbackPath('');
  };
  const isFormValid = () => {
    return tenantID && clientID && clientSecret && providerName && emailDomain && callbackPath;
  };
  const [create, { loading }] = useMutation(CREATE_ADFS, {
    variables: {
      input: {
        tenantID: tenantID,
        clientID: clientID,
        clientSecret: clientSecret,
        providerID: providerName,
        emailDomains: emailDomain,
        callbackPath: callbackPath,
        bksID: licenseId,
      },
    },
    refetchQueries: [
      {
        query: GET_LICENSE,
        variables: { id: licenseId },
      },
      {
        query: GET_ADFS,
      },
    ],
  });
  const allAdfs = useQuery<{ adfs: AdfsSetting[] }>(GET_ADFS);
  const result = allAdfs && allAdfs.data && allAdfs.data.adfs;
  const callbackProviderName = (name: string) => {
    setProviderName(name);
  };
  const callbackProviderNameError = (errorStatus: boolean) => {
    setIsErrorOnProviderName(errorStatus);
  };
  const callbackEmailDomain = (domain: string) => {
    setEmailDomain(domain);
  };
  const callbackEmailDomainError = (errorStatus: boolean) => {
    setIsErrorOnEmailDomain(errorStatus);
  };
  const callbackCallbackPath = (path: string) => {
    setCallbackPath(path);
  };
  const callbackCallbackPathError = (errorStatus: boolean) => {
    setIsErrorOnCallbackPath(errorStatus);
  };
  const callbackTenantID = (tenantID: string) => {
    setTenantID(tenantID);
  };
  const callbackClientID = (clientID: string) => {
    setClientID(clientID);
  };
  const callbackClientSecret = (clientSecret: string) => {
    setClientSecret(clientSecret);
  };
  const handleSave = async () => {
    if (!isErrorOnProviderName && !isErrorOnEmailDomain && !isErrorOnCallbackPath) {
      await create();
      handleClose();
    }
  };
  return (
    <Dialog fullWidth maxWidth="md" onClose={handleCloseBtn} open={true}>
      <DialogTitle>
        Legg til ny ADFS
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {loading && <Loading />}
          </Grid>
          <Grid item xs={12}>
            <ProviderName result={result} callbackValue={callbackProviderName} callbackError={callbackProviderNameError} existingName={''} />
          </Grid>
          <Grid item xs={12}>
            <TenantID callbackValue={callbackTenantID} existingTenant={''} />
          </Grid>
          <Grid item xs={12}>
            <ClientID callbackValue={callbackClientID} existingId={''} />
          </Grid>
          <Grid item xs={12}>
            <ClientSecret callbackValue={callbackClientSecret} existingSecret={''} />
          </Grid>
          <Grid item xs={12}>
            <EmailDomain result={result} callbackValue={callbackEmailDomain} callbackError={callbackEmailDomainError} id={''} domain={[]} />
          </Grid>
          <Grid item xs={12}>
            <CallbackPath result={result} callbackValue={callbackCallbackPath} callbackError={callbackCallbackPathError} existingName={''} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={() => handleClose()}>
          Lukk
        </Button>
        <Button color="primary" variant="contained" disabled={!isFormValid()} onClick={() => handleSave()}>
          LEGG TIL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
