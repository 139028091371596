import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ListItemAddress } from '../../components/ListItemAddress';
import { Code, Delete, People, DateRange } from '@mui/icons-material';
import { formatDateOnly } from '../../Formatters';
import { ListItemCompanyName } from './ListItemCompanyName';
import { ListItemOrgNo } from './ListItemOrgNo';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Address } from '../../interfaces';
import { gql, useQuery } from '@apollo/client';

let query = gql`
  query ($id: Int!) {
    brreg: brreg(licenseId: $id) {
      name
      orgNumber
      code
      codeName
      address {
        address1
        address2
        postalCode
        city
        country
      }
      deletedAt
      numberOfEmployees
      hasSameDataAsUs
      incorporationDate
    }
  }
`;

interface Props {
  licenseId: number;
  setHasDiff(value: boolean): void;
  display: boolean;
}

export function BrregInfo({ licenseId, setHasDiff, display }: Props) {
  const { loading, error, data } = useQuery(query, { variables: { id: licenseId } });

  setHasDiff(data && data.brreg && !data.brreg.hasSameDataAsUs);
  if (!display) return null;

  if (loading) return <LoadingSpinner />;
  if (error || !data) return <Typography>Fikk ikke kontakt med Brreg</Typography>;
  if (!data.brreg) return <Typography>Ingen data funnet</Typography>;

  return <Brreg brreg={data.brreg} />;
}

interface Props2 {
  brreg: BrregInteface;
}

interface BrregInteface {
  name: string;
  orgNumber: string;
  code: string;
  codeName: string;
  address: Address;
  deletedAt?: string;
  numberOfEmployees: number;
  incorporationDate: string;
}

function Brreg({ brreg }: Props2) {
  return (
    <List>
      <ListItemCompanyName companyName={brreg.name} />
      <ListItemOrgNo orgNumber={brreg.orgNumber} />
      <ListItemAddress address={brreg.address} />
      {brreg.code && (
        <ListItem>
          <ListItemIcon title="næringskode">
            <Code />
          </ListItemIcon>
          <ListItemText>
            {brreg.code}, {brreg.codeName}
          </ListItemText>
        </ListItem>
      )}
      {brreg.deletedAt && (
        <ListItem>
          <ListItemIcon title="Slettet">
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText>
            <Typography color="error">{formatDateOnly(brreg.deletedAt)}</Typography>
          </ListItemText>
        </ListItem>
      )}
      <ListItem>
        <ListItemIcon title="Antall ansatte">
          <People />
        </ListItemIcon>
        <ListItemText>{brreg.numberOfEmployees}</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon title="Stiftelsesdato">
          <DateRange />
        </ListItemIcon>
        <ListItemText>{formatDateOnly(brreg.incorporationDate)}</ListItemText>
      </ListItem>
    </List>
  );
}
