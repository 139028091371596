import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import { GET_USER_ACCESS_HISTORY } from '../user.graphql';
import { formatDateOnly } from '../../Formatters';
import { Link } from 'react-router-dom';
import { ColWidths } from '../../components/TableComponents';

export function UserAccessHistoric({ userId }: { userId: number }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button onClick={() => setShow(true)}>Historiske tilganger</Button>
      {show && <UserAccessHistoricDialog userId={userId} close={() => setShow(false)} />}
    </>
  );
}

function UserAccessHistoricDialog({ userId, close }: { userId: number; close(): void }) {
  return (
    <Dialog open={true} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Historiske tilganger</DialogTitle>
      <DialogContent>
        <UserAccessList userId={userId} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function UserAccessList({ userId }: { userId: number }) {
  const { data, loading, error } = useQuery<{ accesses: UserAccessModel[] }>(GET_USER_ACCESS_HISTORY, {
    variables: { id: userId },
    fetchPolicy: 'cache-and-network',
  });

  if (!data && loading) return <LoadingSpinner />;
  if (error) {
    console.error(error.message);
    return <span>Error! {error.message}</span>;
  }

  if (!data || data.accesses.length === 0) {
    return (
      <Typography variant="caption" color="textDisabled">
        Ingen tilganger funnet
      </Typography>
    );
  }

  const accesses = data.accesses;
  return (
    <Table>
      <ColWidths widths={[200, null, 100, 100]} />
      <TableHead>
        <TableRow>
          <TableCell>Navn</TableCell>
          <TableCell>Kunde</TableCell>
          <TableCell>Fra</TableCell>
          <TableCell>Til</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accesses.map((x) => (
          <TableRow key={`${x.subscriptionId}:${x.licenseId}:${x.from}:${x.to}`}>
            <TableCell>{x.subscriptionName}</TableCell>
            <TableCell>
              <Link to={`/license/${x.licenseId}`}>{x.licenseName}</Link>
            </TableCell>
            <TableCell>{x.from ? formatDateOnly(x.from) : null}</TableCell>
            <TableCell>{x.to ? formatDateOnly(x.to) : null}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

interface UserAccessModel {
  subscriptionId: number;
  subscriptionName: string;
  licenseId: number;
  licenseName: string;
  from: string;
  to: string;
}
