import { useState } from 'react';
import { Table, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { formatDate, numberAsKr } from '../../Formatters';
import { Order } from '../../interfaces';
import { Link } from 'react-router-dom';
import {
  Cancel,
  CancelRounded,
  ErrorRounded,
  CheckCircleRounded,
  CreditCard,
  AttachMoney,
  PictureAsPdf,
  Receipt,
  Info,
  Undo,
  Lens,
  LensOutlined,
  NotInterestedOutlined,
  GetApp,
} from '@mui/icons-material';
import { OrderStatus, OrderType, PaymentMode } from '../../interfaces.enums';
import { useConfig } from '../../components/Configuration';
import { CANCEL_ORDER, CREDIT_ORDER } from './Orders.graphql';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConfirmWithInvoiceDialog from './ConfirmWithInvoiceDialog';
import { useMutation } from '@apollo/client';

export function OrderTable({ orders }: { orders: Order[] }) {
  const [numberOfItems, setNumberOfItems] = useState(50);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 45 }}>Nummer</TableCell>
          <TableCell>Bruker</TableCell>
          <TableCell>Kunde</TableCell>
          <TableCell>Beskrivelse</TableCell>
          <TableCell sx={{ width: 200 }} title="Eks. moms">
            Beløp
          </TableCell>
          <TableCell sx={{ width: 25 }}>Type</TableCell>
          <TableCell sx={{ width: 25 }}>Status</TableCell>
          <TableCell sx={{ width: 125 }}>Dato</TableCell>
          <TableCell sx={{ width: 50 }}>Faktura</TableCell>
          <TableCell sx={{ width: 25 }}></TableCell>
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={orders.length > numberOfItems}>
        {orders.slice(0, numberOfItems).map((x) => {
          return (
            <TableRow key={x.id}>
              <TableCell sx={{ textAlign: 'right' }}>{x.orderNumber}</TableCell>
              <TableCell>
                <Customer customer={x.user} registrationEmail={x.registrationEmail} />
              </TableCell>
              <TableCell>
                <License license={x.license} />
              </TableCell>
              <TableCell
                sx={{
                  maxWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                title={x.description}>
                {x.description}
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Amount order={x} />
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Type order={x} />
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Status order={x} />
              </TableCell>
              <TableCell>{formatDate(x.startedAt)}</TableCell>
              <TableCell>
                <Invoices order={x} />
              </TableCell>
              <TableCell>
                <Action order={x} />
              </TableCell>
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}

const Customer = ({ customer, registrationEmail }) => {
  if (!customer && !registrationEmail) return <span>&lt;Slettet&gt;</span>;
  if (!customer) return <span>{registrationEmail}</span>;
  return <Link to={'/user/' + customer.id}>{customer.displayName}</Link>;
};

const License = ({ license }) => {
  if (!license) return null;

  return <Link to={'/license/' + license.id}>{license.companyName ? license.companyName : '<Mangler navn>'}</Link>;
};

const Type = ({ order }: { order: Order }) => {
  if (!order.isInvoiceable)
    return (
      <div title="Ikke-fakturerbar">
        <NotInterestedOutlined />
      </div>
    );
  switch (order.type) {
    case OrderType.Card:
      return (
        <div title="Kort">
          <CreditCard />
        </div>
      );
    case OrderType.Invoice:
      return (
        <div title="Faktura">
          <AttachMoney />
        </div>
      );
    default:
      return order.type;
  }
};

function Status({ order: { status, errorMessage, type, isInvoiceable, paymentMode } }: { order: Order }) {
  switch (status) {
    case OrderStatus.Open:
      return (
        <div title="Åpen">
          <LensOutlined />
        </div>
      );
    case OrderStatus.Cancelled:
      return (
        <div title="Avbrutt">
          <CancelRounded sx={{ color: 'orange' }} />
        </div>
      );
    case OrderStatus.Failed:
      return (
        <div title={'Feilet: ' + errorMessage}>
          <ErrorRounded sx={{ color: 'red' }} />
        </div>
      );
    case OrderStatus.Reserved:
      return (
        <div title="Beløp reservert">
          <Lens sx={{ color: 'green' }} />
        </div>
      );
    case OrderStatus.Completed:
      if (type === OrderType.Card)
        return (
          <div title="Betalt">
            <CheckCircleRounded sx={{ color: 'green' }} />
          </div>
        );
      if (!isInvoiceable)
        return (
          <div title="Markert som ikke-fakturerbar">
            <CheckCircleRounded sx={{ color: 'green' }} />
          </div>
        );
      if (paymentMode === PaymentMode.Manual)
        return (
          <div title="Opprettet i Maconomy. Håndteres manuelt.">
            <Info sx={{ color: 'blue' }} />
          </div>
        );

      return (
        <div title="Faktura sendt">
          <CheckCircleRounded sx={{ color: 'green' }} />
        </div>
      );
    case OrderStatus.Fullfilled:
      return (
        <div title="Faktura sendt manuelt">
          <CheckCircleRounded sx={{ color: 'green' }} />
        </div>
      );
    case OrderStatus.Credited:
      return (
        <div title="Kreditert">
          <CheckCircleRounded />
        </div>
      );
    default:
      return status;
  }
}

function Invoices({ order }: { order: Order }) {
  return (
    <>
      <Invoice order={order} />
      <CreditInvoice order={order} />
    </>
  );
}

function Invoice({ order }: { order: Order }) {
  if (order.invoiceContentId) return <Pdf title="Åpne faktura i ny fane" contentId={order.invoiceContentId} />;

  if (order.invoiceNumber > 0) {
    return <Receipt titleAccess={`Faktura opprettet: ${order.invoiceNumber}`} />;
  }

  return null;
}

function CreditInvoice({ order }: { order: Order }) {
  if (order.creditInvoiceContentId) return <Pdf title="Åpne kreditnota i ny fane" contentId={order.creditInvoiceContentId} />;

  if (order.creditInvoiceNumber) {
    return <Receipt titleAccess={`Kreditnota opprettet: ${order.creditInvoiceNumber}`} />;
  }

  return null;
}

function Pdf({ title, contentId }: { title: string; contentId: string }) {
  const config = useConfig();
  const rootUrl = config ? config.apiUrl : '/';
  return (
    <a title={title} href={rootUrl + '/content/' + contentId} target="_blank" rel="noreferrer">
      <PictureAsPdf />
    </a>
  );
}

const Action = ({ order }: { order: Order }) => {
  if (order.type === OrderType.Card && (order.status === OrderStatus.Open || order.status === OrderStatus.Reserved)) {
    return <AbortAction order={order} />;
  }

  if (order.status === OrderStatus.Failed) {
    return <UpdateStatusAction order={order} />;
  }

  if (order.invoiceNumber && !order.creditInvoiceNumber) {
    return <CreditAction order={order} />;
  } else return null;
};

function CreditAction({ order }: { order: Order }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [credit, { called, loading }] = useMutation(CREDIT_ORDER, { variables: { input: { id: order.id } } });
  const doCredit = () => {
    setShowConfirm(false);
    credit();
  };

  return (
    <>
      <IconButton disabled={called || loading} onClick={() => setShowConfirm(true)} size="small" title="Kreditér ordre">
        <Undo fontSize="small" />
      </IconButton>
      <ConfirmDialog
        open={showConfirm}
        title="Kreditér ordre"
        message={`Vil du kreditére ordre ${order.orderNumber}?`}
        confirmText="Kreditér"
        deny={() => setShowConfirm(false)}
        confirm={doCredit}
      />
    </>
  );
}

function UpdateStatusAction({ order }: { order: Order }) {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <IconButton onClick={() => setShowConfirm(true)} size="small" title="Oppdater status">
        <GetApp fontSize="small" />
      </IconButton>
      <ConfirmWithInvoiceDialog
        order={order}
        open={showConfirm}
        title="Oppdater status"
        orderNumber={order.orderNumber}
        deny={() => setShowConfirm(false)}
      />
    </>
  );
}

function AbortAction({ order }: { order: Order }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [cancel, { called, loading }] = useMutation(CANCEL_ORDER, { variables: { input: { id: order.id } } });
  const doCancel = () => {
    setShowConfirm(false);
    cancel();
  };

  return (
    <>
      <IconButton disabled={called || loading} onClick={() => setShowConfirm(true)} size="small" title="Avbryt ordre">
        <Cancel fontSize="small" />
      </IconButton>
      <ConfirmDialog
        open={showConfirm}
        title="Avbryt ordre"
        message={`Vil du avbryte ordre ${order.orderNumber}?`}
        confirmText="Avbryt ordre"
        denyText="Lukk"
        deny={() => setShowConfirm(false)}
        confirm={doCancel}
      />
    </>
  );
}

function Amount({ order }: { order: Order }) {
  const amount = numberAsKr(order.amount);
  if (!order.isInvoiceable) return <span>Ikke-fakturert {amount}</span>;
  if (order.status === OrderStatus.Credited) return <span>Kreditert {amount}</span>;
  return <span>{amount}</span>;
}
