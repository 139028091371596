import React from 'react';
import { Divider, Grid2, Box, Avatar } from '@mui/material';

interface BannerProps {
  icon?: any;
  action?: any;
  children: any;
}

export function Banner({ icon, action, children }: BannerProps) {
  return (
    <>
      <Box py={2} px={2}>
        <Grid2 container alignItems="center">
          {icon && (
            <Grid2 size={{ xs: 1 }}>
              <Avatar sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>{icon}</Avatar>
            </Grid2>
          )}
          <Grid2>{children}</Grid2>
          {action && <Grid2 size={{ xs: 2 }}>{action}</Grid2>}
        </Grid2>
      </Box>
      <Divider sx={{ marginBottom: (theme) => theme.spacing(2) }} />
    </>
  );
}
