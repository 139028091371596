import React from 'react';
import { TableCell, Typography, TableSortLabel } from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import moment, { Moment } from 'moment';
// This file contains components to help formatting of MUI tables in a uniform manner.

// ColWidths
// Purpose: Set with on columns in a MUI table component
// Usage: Put right under <Table> tag.
// Arguments: widths: Array of column widths (number or null).
// Example <ColWidths widths={[40,100,null,160,100,80]}></ColWidths>
interface widths {
  widths: (number | null)[];
}

export const ColWidths = (props: widths) => {
  const widths = props.widths;
  return (
    <colgroup>
      {widths.map((w, index) => {
        return <col key={index} style={w ? { width: w } : {}}></col>;
      })}
    </colgroup>
  );
};

// TableCellHead
// Purpose: Overrides default header style for table
export const TableCellHead = (props: any) => {
  return (
    <TableCell {...props} className={props.className} sx={{ verticalAlign: 'bottom' }}>
      <Typography
        sx={{
          color: '#8b8b8b',
          fontSize: 12,
        }}>
        {props.children}
      </Typography>
    </TableCell>
  );
};

export const TableCellSort = ({
  children,
  onClick,
  sortDirection,
  active,
  inputProps,
}: {
  active: boolean;
  sortDirection: SortDirection;
  onClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>);
  children?: any;
  inputProps?: any;
}) => {
  return (
    <TableCell {...inputProps} sortDirection={active ? sortDirection : false} sx={{ verticalAlign: 'bottom' }}>
      <TableSortLabel active={active} direction={sortDirection ? sortDirection : 'asc'} onClick={onClick}>
        <Typography
          sx={{
            color: '#8b8b8b',
            fontSize: 12,
          }}>
          {children}
        </Typography>
      </TableSortLabel>
    </TableCell>
  );
};

export function compareFn<T>(fn: (a: T) => any, sortDirection: SortDirection): (a: T, b: T) => number {
  return (a: T, b: T) => compareWithDirection(fn(a), fn(b), sortDirection);
}

function compareWithDirection<T>(a: T, b: T, direction: SortDirection): number {
  return direction === 'desc' ? compare(b, a) : compare(a, b);
}

function compare<T>(a: T, b: T): number {
  if (!a && b) {
    return -1;
  }
  if (a && !b) {
    return 1;
  }
  if (!a && !b) {
    return 0;
  }
  if (typeof a === 'number' && typeof b === 'number') return compareNumber(a, b);
  if (typeof a === 'string' && typeof b === 'string') return compareString(a, b);
  if (typeof a === 'boolean' && typeof b === 'boolean') return compareBool(a, b);
  if (moment.isMoment(a) && moment.isMoment(b)) {
    return compareMoment(a, b);
  }
  return 0;
}

const compareString = (a: string, b: string): number => a.localeCompare(b);
const compareBool = (a: boolean, b: boolean) => (a === b ? 0 : a ? 1 : -1);
const compareNumber = (a: number, b: number) => a - b;
const compareMoment = (a: Moment, b: Moment) => (a.isSame(b) ? 0 : a.isBefore(b) ? -1 : 1);

// TableCellEllipsis
// Purpose: To hadle table cells with long texts where line breaks are to be avoided.
//          Shortens text dynamically depending on column with and adds ellipsis

export const TableCellEllipsis = (props: any) => {
  return (
    <TableCell {...props} sx={{ maxWidth: 0 }}>
      <span
        style={{
          whiteSpace: 'nowrap',
          display: 'block',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        title={props.title}>
        {props.children}
      </span>
    </TableCell>
  );
};

// TableCellIcon
// Purpose: To fit a MUI Icon component in a table cell without affecting row-hight
// Note: To fit a IconButton in a cell without expanding the row vertically, its padding must be set to 5 (default is 12)
// <IconButton style={{padding: 5}}>...</IconButton>

export const TableCellIcon = (props: any) => {
  return (
    <TableCell {...props} sx={{ padding: '0 16px 0 16px' }}>
      <span className={props.className} title={props.title}>
        {props.children}
      </span>
    </TableCell>
  );
};
