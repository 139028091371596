import React, { useState } from 'react';
import { Subscription } from '../../interfaces';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Checkbox, DialogContentText } from '@mui/material';

interface Props {
  open: boolean;
  childSubscriptions: Subscription[];
  onCancel: () => void;
  onSubmit: (data: number[], effectuateNow?: boolean) => void;
}

export default function DowngradeSubscriptionDialog({ open, childSubscriptions, onCancel, onSubmit }: Props) {
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<number[]>([]);

  const noneSelected = selectedSubscriptions.length === 0;

  const cancel = () => {
    setSelectedSubscriptions([]);
    onCancel();
  };
  const submit = (effectuateNow?: boolean) => {
    onSubmit(selectedSubscriptions, effectuateNow);
    setSelectedSubscriptions([]);
  };

  const isChecked = (id: number) => selectedSubscriptions.some((x) => x === id);

  const handleChecked = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.checked
      ? setSelectedSubscriptions([...selectedSubscriptions, id])
      : setSelectedSubscriptions(selectedSubscriptions.filter((x) => x !== id));

  return (
    <Dialog open={open} onClose={() => cancel()} maxWidth="sm" fullWidth>
      <DialogTitle>Nedgradering til delserier</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Abonnementet avsluttes. Det opprettes abonnementer for valgte delserier med de samme brukerne og tilgangene som før. Nedgraderingen trer i
          kraft ved neste fakturering.
        </DialogContentText>
        <List>
          {childSubscriptions.map((s) => (
            <ListItem key={s.id}>
              <Checkbox checked={isChecked(s.id)} onChange={handleChecked(s.id)} />
              <ListItemText>{s.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => cancel()}>Avbryt</Button>
        <Button disabled={noneSelected} variant="contained" color="primary" onClick={() => submit()}>
          Nedgrader
        </Button>
        <Button
          disabled={noneSelected}
          variant="contained"
          color="primary"
          sx={{
            background: (theme) => theme.palette.error.main,
            '&:hover, &:focus': {
              backgroundColor: (theme) => theme.palette.error.dark,
            },
          }}
          onClick={() => submit(true)}
          title="Dette vil nedgradere til delserie med umiddelbar virkning">
          Nedgrader nå
        </Button>
      </DialogActions>
    </Dialog>
  );
}
