import React, { FC } from 'react';
import { Divider, List, Drawer, MenuItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RouteDef } from './Shell';

interface SidebarProps {
  routes: RouteDef[];
  mobileOpen: boolean;
  handleDrawerClose(): void;
  handleDrawerTransitionEnd(): void;
}

const drawerWidth = 240;

export const Sidebar: FC<SidebarProps> = ({ routes, mobileOpen, handleDrawerClose, handleDrawerTransitionEnd }) => {
  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}>
        <SidebarMenu routes={routes} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open>
        <SidebarMenu routes={routes} />
      </Drawer>
    </>
  );
};

const SidebarMenu: FC<{ routes: RouteDef[] }> = ({ routes }) => {
  return (
    <div>
      <Box sx={(theme) => theme.mixins.toolbar} style={{ backgroundColor: '#FAFAFA' }}>
        <Link to={'/'}>
          <img
            src="/longhouse.svg"
            style={{
              width: 60,
              height: 60,
              marginLeft: 20,
            }}
            alt=""></img>
        </Link>
      </Box>
      <Divider />
      <List>
        {routes
          .filter((x) => x.title)
          .map((x, index) => (
            <ListItemLink key={index} route={x} />
          ))}
      </List>
    </div>
  );
};

const ListItemLink: FC<{ route: RouteDef }> = ({ route }) => {
  const history = useHistory();
  const location = useLocation();
  const { path, title, icon } = route;
  let click = () => {
    history.push(path);
  };

  let currentPath = location.pathname;
  return (
    <MenuItem onClick={click} selected={path === '/' ? currentPath === path : currentPath.startsWith(path)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <Link
          style={{
            color: 'black',
            textDecoration: 'none',
          }}
          to={path}>
          {title}
        </Link>
      </ListItemText>
    </MenuItem>
  );
};
