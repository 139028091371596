import React, { useState } from 'react';
import {
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ListItemAddress } from '../../components/ListItemAddress';
import { ListItemCompanyName } from './ListItemCompanyName';
import Loading from '../../components/LoadingSpinner';
import { Link, DateRange, Receipt, CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import { GET_MACONOMY_ORDER } from './Maconomy.graphql';
import moment from 'moment';
import { useConfig } from '../../components/Configuration';
import { formatDateOnly, numberAsKr } from '../../Formatters';
import { useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
  display: boolean;
  setHasDiff(value: boolean): void;
}

interface Subscription {
  companyName: string;
  orderNumber: string;
  invoiceStartingDate: string;
  attention: string;
  address: Address;
  hasSameDataAsUs: boolean;
  lines: OrderLine[];
}

interface Address {
  id: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  country: string;
}

interface OrderLine {
  subscriptionType: string;
  subscriptionNumber: string;
  numberOf: number;
  amount: number;
  comment: string;
  deliveryStartingDate: string;
  invoiceStartingDate: string;
  dateOrdered: string;
  invoicedUntil: string;
  nextInvoiceDate: string;
  discountPercentage: number;
  lineNumber: number;
  deliveryEndingDate: string;
  invoiceEndingDate: string;
  nonInvoiceable: boolean;
}

export function MaconomySubscription({ licenseId, display, setHasDiff }: Props) {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval * 2 : 0;
  const { loading, error, data } = useQuery<{ maconomySubscription: Subscription }>(GET_MACONOMY_ORDER, {
    variables: { licenseId },
    pollInterval: pollIntervall,
  });

  setHasDiff((data && data.maconomySubscription && !data.maconomySubscription.hasSameDataAsUs) === true);

  if (!display) return null;
  if (loading) return <Loading />;
  if (error || !data) return <Typography>Fikk ikke kontakt med Maconomy</Typography>;
  if (!data.maconomySubscription) return <Typography>Ingen data funnet</Typography>;

  return <MaconomyCustomerCard customer={data.maconomySubscription} />;
}

function MaconomyCustomerCard({ customer }: { customer: Subscription }) {
  const [showOrderLines, setShowOrderLines] = useState(false);
  var nextInvoiceDate = moment(customer.invoiceStartingDate).year(moment().year());
  if (nextInvoiceDate < moment()) {
    nextInvoiceDate = nextInvoiceDate.add(1, 'year');
  }

  return (
    <>
      <List>
        <ListItemCompanyName companyName={customer.companyName} />
        <ListItemAddress address={customer.address} />
        <ListItem>
          <ListItemIcon title="Faktura ref">
            <Receipt />
          </ListItemIcon>
          <ListItemText>{customer.attention}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon title="Neste faktura dato">
            <DateRange />
          </ListItemIcon>
          <ListItemText>{nextInvoiceDate.format('DD.MM.YYYY')}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon title="Ordrenummer">
            <Link />
          </ListItemIcon>
          <ListItemText>{customer.orderNumber}</ListItemText>
        </ListItem>
      </List>
      <Button onClick={(_) => setShowOrderLines(true)}>Se ordrelinjer</Button>
      {showOrderLines && <OrderLinesDialog lines={customer.lines} onClose={() => setShowOrderLines(false)} />}
    </>
  );
}

function OrderLinesDialog({ onClose, lines }: { onClose(): void; lines: OrderLine[] }) {
  const isDeleted = (x: OrderLine): boolean => {
    if (x.nonInvoiceable) return true;
    if (isMinimumDate(x.invoiceEndingDate)) return false;

    return moment(x.invoiceEndingDate) <= moment(x.invoicedUntil);
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth={false}>
      <DialogTitle>Ordrelinjer i Maconomy</DialogTitle>
      <DialogContent>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nr</TableCell>
              <TableCell>Abonnement</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Antall</TableCell>
              <TableCell align="right">Beløp</TableCell>
              <TableCell align="right">Rabatt</TableCell>
              <TableCell align="right">Bestillt</TableCell>
              <TableCell align="right">Start</TableCell>
              <TableCell align="right">Faktureres fra</TableCell>
              <TableCell align="right">Fakturerert til</TableCell>
              <TableCell align="right">Neste fakturering</TableCell>
              <TableCell align="right">Slutt</TableCell>
              <TableCell align="right">Faktureres til</TableCell>
              <TableCell align="right">Ikke-fakturerbar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((x) => (
              <TableRow sx={{ backgroundColor: (theme) => (isDeleted(x) ? theme.palette.grey[100] : undefined) }} key={x.lineNumber}>
                <TableCell>{x.lineNumber}</TableCell>
                <TableCell>{x.subscriptionNumber}</TableCell>
                <TableCell>{x.subscriptionType === 'Basic' ? 'Basis' : 'Ekstra'}</TableCell>
                <TableCell align="right">{x.numberOf}</TableCell>
                <TableCell align="right">{numberAsKr(x.amount)}</TableCell>
                <TableCell align="right">{x.discountPercentage}</TableCell>
                <TableDateCell date={x.dateOrdered} />
                <TableDateCell date={x.deliveryStartingDate} />
                <TableDateCell date={x.invoiceStartingDate} />
                <TableDateCell date={x.invoicedUntil} />
                <TableDateCell date={x.nextInvoiceDate} />
                <TableDateCell date={x.deliveryEndingDate} />
                <TableDateCell date={x.invoiceEndingDate} />
                <TableCell align="center">{x.nonInvoiceable ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TableDateCell({ date }: { date: string }) {
  if (isMinimumDate(date)) return <TableCell></TableCell>;

  return <TableCell align="right">{formatDateOnly(date)}</TableCell>;
}

function isMinimumDate(date: string) {
  const formattedDate = formatDateOnly(date);
  return formattedDate === '1.1.0001';
}
