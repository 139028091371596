import React, { useState } from 'react';
import { MainContentPanel } from '../../components/MainContentPanel';
import { Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { formatDateOnly } from '../../Formatters';
import { Link } from 'react-router-dom';
import { RemoveUserAccessDialog } from '../../licenses/details/RemoveUserAccessDialog';
import { GET_ACCESSES } from '../user.graphql';
import LoadingSpinner from '../../components/LoadingSpinner';
import { License, UserAccess } from '../interfaces';

import { GET_ACCESSES_FOR_USER } from '../../pdf/pdf.graphql';
import { useQuery } from '@apollo/client';
import { UserAccessHistoric } from './UserAccessHistoric';

export function UserAccessCard({ userId }: { userId: number }) {
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Tilganger
      </Typography>
      <SubscriptionAccessList userId={userId} />
      <SingleDocumentAccessList userId={userId} />
      <UserAccessHistoric userId={userId} />
    </MainContentPanel>
  );
}

function SubscriptionAccessList({ userId }: { userId: number }) {
  const { loading, data } = useQuery<{ userAccess: UserAccess[] }>(GET_ACCESSES, { variables: { id: userId } });
  const [customerAccessToRemove, setCustomerAccessToRemove] = useState<UserAccess | undefined>(undefined);

  if (loading) return <LoadingSpinner />;
  if (!data)
    return (
      <Typography variant="caption" color="textDisabled">
        Har ingen tilganger
      </Typography>
    );

  const grouped = groupByLicense(data.userAccess);

  return (
    <>
      {customerAccessToRemove && (
        <RemoveUserAccessDialog
          displayName={customerAccessToRemove.subscription.name}
          userId={userId}
          userAccessId={customerAccessToRemove.id}
          licenseId={customerAccessToRemove.license.id}
          onClose={() => setCustomerAccessToRemove(undefined)}
        />
      )}
      {grouped.map((x) => (
        <div key={x.license.id}>
          <Typography>
            gjennom <Link to={`/license/${x.license.id}`}>{x.license.companyName || '<Mangler navn>'}</Link>
          </Typography>
          <AccessList accessList={x.accessList} onDelete={setCustomerAccessToRemove} />
        </div>
      ))}
    </>
  );
}

function AccessList({ accessList, onDelete }: { accessList: UserAccess[]; onDelete(access: UserAccess): void }) {
  return (
    <List>
      {accessList.map((sub) => {
        const primary = sub.subscription.name;
        const secondary = `fra ${formatDateOnly(sub.createdAt)}`;
        return (
          <ListItem key={sub.subscription.id}>
            <ListItemText primary={primary} secondary={secondary} />
            <ListItemSecondaryAction>
              <IconButton title="Fjern" onClick={() => onDelete(sub)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

function groupByLicense(accessList: UserAccess[]): { license: License; accessList: UserAccess[] }[] {
  const grouped = accessList
    .filter((x) => !x.isDeleted)
    .reduce((g: any, lic: UserAccess) => {
      g[lic.license.id] = g[lic.license.id] || [];
      g[lic.license.id].push(lic);
      return g;
    }, {});

  return Object.values(grouped).map((x) => {
    const list = x as UserAccess[];
    return { license: list[0].license as License, accessList: list };
  });
}

function SingleDocumentAccessList({ userId }: { userId: number }) {
  const { loading, data } = useQuery(GET_ACCESSES_FOR_USER, { variables: { userId } });

  if (loading) return <LoadingSpinner />;
  if (!data) return null;
  const list = data.accesses;
  if (list.length === 0) return null;

  return (
    <List dense>
      {list.map((x) => (
        <ListItem key={x.id}>
          <ListItemText primary={`${x.docName} ${x.title}`} secondary={`fra ${formatDateOnly(x.validFrom)} til ${formatDateOnly(x.validTo)}`} />
        </ListItem>
      ))}
    </List>
  );
}
