import React, { useState, ReactNode } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ViewsPerDoc from './ViewsPerDoc';
import ViewsPerUser from './ViewsPerUser';
import DownloadsPerUser from './DownloadsPerUser';
import { SelectChangeEvent } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface CompanyDetailsProps {
  onBackClick: () => void;
  orgNumber: string;
  companyName: string;
}
export default function CompanyDetail(props: CompanyDetailsProps) {
  const { onBackClick, orgNumber, companyName } = props;

  const oneYearAgo = moment().subtract(1, 'year');
  const [fromDate, setFromDate] = useState<moment.Moment | null>(oneYearAgo);
  const fromDateStr = fromDate?.toDate().toDateString();

  const [toDate, setToDate] = useState<moment.Moment | null>(moment());
  const toDateStr = toDate?.toDate().toDateString();

  const [reportType, setReportType] = useState('perDocument');
  const [productType, setProductType] = useState('alle');

  const handleReportTypeChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    setReportType(event.target.value as string);
  };

  const handleProductTypeChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    setProductType(event.target.value as string);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '16px',
        }}>
        <IconButton onClick={onBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">{companyName}</Typography>
      </Box>
      <Box
        sx={{
          padding: '16px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          marginBottom: '16px',
          marginLeft: '16px',
          marginRight: '16px',
          display: 'flex',
        }}>
        <FormControl sx={{ mr: 2, minWidth: '300px' }}>
          <InputLabel id="report-type-label">Velg rapport type</InputLabel>
          <Select labelId="report-type-label" id="report-type" value={reportType} onChange={handleReportTypeChange}>
            <MenuItem value="perDocument">Sidevisning per dokument</MenuItem>
            <MenuItem value="perUser">Sidevisning per bruker</MenuItem>
            <MenuItem value="perUserDownload">Nedlasting per bruker</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ mr: 2, minWidth: '300px' }}>
          <InputLabel id="report-type-label">Velg produkt type</InputLabel>
          <Select labelId="report-type-label" id="report-type" value={productType} onChange={handleProductTypeChange}>
            <MenuItem value="alle">Alle</MenuItem>
            <MenuItem value="bfs">Byggforskserien</MenuItem>
            <MenuItem value="bfsp">Planlegging</MenuItem>
            <MenuItem value="bfsb">Byggdetaljer</MenuItem>
            <MenuItem value="bfsf">Byggforvaltning</MenuItem>
            <MenuItem value="bvn">Våtromsnormen</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ marginRight: '5px' }}>
          <DatePicker label="Fra dato" value={fromDate} onChange={setFromDate} />
        </Box>
        <DatePicker
          sx={{
            marginRight: '16px',
            width: '200px',
          }}
          label="Til dato"
          value={toDate}
          onChange={setToDate}
        />
      </Box>
      {reportType === 'perDocument' ? (
        <ViewsPerDoc orgNumber={orgNumber} fromDate={fromDateStr} toDate={toDateStr} productType={productType} />
      ) : reportType === 'perUserDownload' ? (
        <DownloadsPerUser orgNumber={orgNumber} fromDate={fromDateStr} toDate={toDateStr} productType={productType} />
      ) : (
        <ViewsPerUser orgNumber={orgNumber} fromDate={fromDateStr} toDate={toDateStr} productType={productType} />
      )}
    </>
  );
}
