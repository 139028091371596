import React, { FC } from 'react';
import { Grid2, Typography } from '@mui/material';

interface Props {
  labelWidth: number;
  label: string;
  text: string;
  fragment?: React.ReactNode;
  labelProps?: any;
  textProps?: any;
  preWrap?: boolean;
  multiline?: boolean;
}
const Property: FC<Props> = ({ labelWidth, label, text, fragment, labelProps, textProps, preWrap, multiline }) => {
  return (
    <Grid2 container alignItems={multiline ? 'flex-start' : 'center'} mt={(theme) => theme.spacing()} mb={(theme) => theme.spacing()}>
      <Typography variant="subtitle1" {...labelProps} style={{ width: labelWidth }}>
        {label}
      </Typography>
      <Typography variant="body2" component="div" style={preWrap && { whiteSpace: 'pre-wrap' }} {...textProps}>
        {fragment || text}
      </Typography>
    </Grid2>
  );
};

export default Property;
