import React, { FC, useState } from 'react';
import { CircularProgress, Grid2, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import LoadingSpinner from '../components/LoadingSpinner';
import { formatDate, numberAsKr } from '../Formatters';
import { CANCEL_PRICE_UPDATE, GET_PRICE } from './pdf.graphql';
import { Alert } from '@mui/material';
import { UpdatePriceDialog } from './UpdatePriceDialog';
import { useMutation, useQuery } from '@apollo/client';

interface PriceChange {
  id: string;
  timeOfChange: string;
  newPrice: number;
}
export interface GuidePriceModel {
  id: string;
  price: number;
  pendingPriceChange: PriceChange;
}

export const PdfPriceCard: FC = () => {
  const { loading, data } = useQuery<{ pdf: GuidePriceModel }>(GET_PRICE);

  return (
    <Stack>
      <Typography variant="h5" gutterBottom>
        Enkeltanvisning
      </Typography>
      {loading && <LoadingSpinner />}
      {data && <PdfTable pdf={data.pdf} />}
    </Stack>
  );
};

const PdfTable: FC<{ pdf: GuidePriceModel }> = ({ pdf }) => {
  return (
    <Grid2 container>
      <Grid2>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell align="right">Pris</TableCell>
              <TableCell style={{ width: 355 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Pdf</TableCell>
              <TableCell align="right">
                <Price pdf={pdf} />
              </TableCell>
              <TableCell>
                <PriceAction pdf={pdf} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid2>
    </Grid2>
  );
};
function Price({ pdf }: { pdf: GuidePriceModel }) {
  const price = pdf.price;
  if (!price) return null;
  const priceAsKr = numberAsKr(price);
  if (!pdf.pendingPriceChange) return <span>{priceAsKr}</span>;
  return (
    <>
      <Typography sx={{ textDecoration: 'line-through' }} color="error" variant="body1" display="inline">
        {priceAsKr}
      </Typography>
      <Typography color="primary" variant="body1" display="inline">
        {numberAsKr(pdf.pendingPriceChange.newPrice)}
      </Typography>
    </>
  );
}

const PriceAction: FC<{ pdf: GuidePriceModel }> = ({ pdf }) => {
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [cancelUpdate, { loading }] = useMutation(CANCEL_PRICE_UPDATE);
  if (!pdf.price) return null;

  if (pdf.pendingPriceChange) {
    return (
      <Stack direction="row" alignItems="center">
        <div>
          <IconButton disabled={loading} title="Forkast endring" onClick={() => cancelUpdate()} size="small">
            <Delete fontSize="small" />
          </IconButton>
        </div>
        <Alert
          sx={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            pt: 0,
            pb: 0,
            '& .MuiAlert-message': {
              padding: 0,
            },
            '& .MuiAlert-icon': {
              padding: 0,
            },
          }}
          severity="info">
          Prisen vil bli oppdatert {formatDate(pdf.pendingPriceChange.timeOfChange)}
        </Alert>
      </Stack>
    );
  } else {
    return (
      <Stack direction="row">
        <IconButton disabled={busy} title="Oppdater prisen" onClick={() => setOpen(true)} size="small">
          <Edit fontSize="small" />
        </IconButton>
        {open && <UpdatePriceDialog pdf={pdf} onClose={() => setOpen(false)} setBusy={setBusy} />}
        {busy && <CircularProgress sx={{ verticalAlign: `middle` }} size={30} />}
      </Stack>
    );
  }
};
