import React from 'react';
import { Typography } from '@mui/material';

export function Counter({ list }: { list: any[] }) {
  return (
    <div style={{ textAlign: 'right' }}>
      <Typography variant="caption">Antall: {list.length.toLocaleString('nb-NO')}</Typography>
    </div>
  );
}
