import { useCurrents, useNotifications, useRenewals } from '../useData';
import { RenewalInfo } from '../products';

export function useCurrentSummary() {
  const { renewals, loading } = useCurrents();

  return {
    summary: !loading ? createSummary(renewals ?? []) : undefined,
  };
}
export function useRenewalSummary() {
  const { renewals, loading } = useRenewals();

  return {
    summary: !loading ? createSummary(renewals ?? []) : undefined,
  };
}

export function useNotificationDiffSummary() {
  const { summary: notificationSummary } = useNotificationSummary();
  const { summary: currentSummary } = useCurrentSummary();

  return {
    summary: createDiffSummary(notificationSummary, currentSummary),
  };
}

export function useNotificationDiffPercentageSummary() {
  const { summary: notificationSummary } = useNotificationSummary();
  const { summary: currentSummary } = useCurrentSummary();

  return {
    summary: createDiffPercentageSummary(notificationSummary, currentSummary),
  };
}

export function useRenewalDiffSummary() {
  const { summary: renewalSummary } = useRenewalSummary();
  const { summary: currentSummary } = useCurrentSummary();

  return {
    summary: createDiffSummary(renewalSummary, currentSummary),
  };
}

function createDiffSummary(summary: SummaryType, baseline: SummaryType) {
  if (!summary || !baseline) return undefined;

  const calcDiff = (selector: (item: NonNullable<SummaryType>) => number) => selector(baseline) - selector(summary);

  return createCompositeSummary(calcDiff);
}

function createDiffPercentageSummary(summary: SummaryType, baseline: SummaryType) {
  if (!summary || !baseline) return undefined;

  const calcPercentage = (selector: (item: NonNullable<SummaryType>) => number) => (selector(baseline) - selector(summary)) / selector(summary);
  return createCompositeSummary(calcPercentage);
}

function createCompositeSummary(calculate: (selector: (item: NonNullable<SummaryType>) => number) => number) {
  return {
    numberOfCustomers: calculate((x) => x.numberOfCustomers),
    bfs: {
      total: calculate((x) => x.bfs.total),
      komplett: {
        total: calculate((x) => x.bfs.komplett.total),
        planlegging: calculate((x) => x.bfs.komplett.planlegging),
        byggdetaljer: calculate((x) => x.bfs.komplett.byggdetaljer),
        byggforvaltning: calculate((x) => x.bfs.komplett.byggforvaltning),
        utførelse: calculate((x) => x.bfs.komplett.utførelse),
      },
    },
    bvn: calculate((x) => x.bvn),
    total: calculate((x) => x.total),
  };
}

export function useNotificationSummary() {
  const { renewals, loading } = useNotifications();

  return {
    summary: !loading ? createSummary(renewals ?? []) : undefined,
  };
}

export type SummaryType = ReturnType<typeof useRenewalSummary>['summary'];

export function createSummary(renewals: RenewalInfo[]) {
  if (renewals.length === 0) return null;
  const sumProduct = (sel: (item: RenewalInfo) => number | undefined) => renewals.reduce((sum, renewal) => sum + (sel(renewal) ?? 0), 0);
  const productsWithBfsSelector = (x: RenewalInfo) => sumNullable(x.bfs, x.bfsp, x.bfsb, x.bfsf, x.bfsu);

  const totalAtRenewal = renewals.reduce((sum, product) => sum + product.total, 0);

  return {
    numberOfCustomers: renewals.length,
    bfs: {
      total: sumProduct(productsWithBfsSelector),
      komplett: {
        total: sumProduct((x) => x.bfs),
        planlegging: sumProduct((x) => x.bfsp),
        byggdetaljer: sumProduct((x) => x.bfsb),
        byggforvaltning: sumProduct((x) => x.bfsf),
        utførelse: sumProduct((x) => x.bfsu),
      },
    },
    bvn: sumProduct((x) => x.bvn),
    total: totalAtRenewal,
  };
}

function sumNullable(...values: (number | undefined)[]): number | undefined {
  const onlyNumbers = values.filter((x) => x !== undefined) as number[];
  return onlyNumbers.reduce((sum, current) => sum + current, 0);
}
