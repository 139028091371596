import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { OrderSearchableTable } from './OrderSearchableTable';
import { GET_ORDERS } from './Orders.graphql';
import { MainContentPanel } from '../../components/MainContentPanel';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Order } from '../../interfaces';
import { useConfig } from '../../components/Configuration';
import { useQuery } from '@apollo/client';

export default function Overview() {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { data, loading } = useQuery<{ orders: Order[] }>(GET_ORDERS, {
    variables: { showIncomplete, limit: showAll ? null : 100 },
    pollInterval: pollIntervall,
  });
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Ordre
      </Typography>
      {loading && <LoadingSpinner />}
      {data && <OrderSearchableTable orders={data.orders} showIncomplete={showIncomplete} setShowIncomplete={setShowIncomplete} />}
      {data && !showAll && (
        <Box
          sx={{
            textAlign: 'center',
            marginTop: (theme) => theme.spacing(),
          }}>
          <Button onClick={(_) => setShowAll(true)}>Hent resten</Button>
        </Box>
      )}
    </MainContentPanel>
  );
}
