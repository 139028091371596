import React, { ChangeEvent, useState } from 'react';
import { MainContentPanel } from '../../components/MainContentPanel';
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material';
import { Share, Block, Close, FileCopy, Delete, Edit, LocalOffer, Mail } from '@mui/icons-material';
import {
  GET_DISCOUNT,
  GET_USAGES,
  DEACTIVATE_DISCOUNT,
  DELETE_DISCOUNT,
  GET_DISCOUNTS,
  UPDATE_DISCOUNT,
  UPDATE_VALUE,
  UPDATE_EMAIL_FILTER,
  GET_DISCOUNT_TOKEN,
} from '../discount.graphql';
import { Summary } from './Summary';
import { Discount, DiscountOverview, Order } from '../../interfaces';
import LoadingSpinner from '../../components/LoadingSpinner';
import { LicenseTable } from '../../licenses/overview/LicenseTable';
import { useConfig } from '../../components/Configuration';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Redirect } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Config } from '../../config';
import { DiscountType } from '../../interfaces.enums';
import { Counter } from '../../components/Counter';
import { GET_COMPLETED_ORDERS_WITH_DISCOUNT } from '../../orders/overview/Orders.graphql';
import { LicenseOverviewModel } from '../../licenses/overview/ColumnDefinitions';
import { useMutation, useQuery } from '@apollo/client';
import { initialSettings } from '../../licenses/overview/ColumnDefinitions';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';

const boxHeight = 299;

interface QueryResult {
  discount: Discount;
}

export function DiscountDetails(props) {
  const id = props.match.params.id as string;

  return (
    <>
      <Details id={id} />
      <Usage id={id} />
    </>
  );
}

function Details({ id }) {
  const { loading, data } = useQuery<QueryResult>(GET_DISCOUNT, { variables: { id } });

  if (loading || !data) return <LoadingSpinner />;

  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <SummaryPanel discount={data.discount} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Actions discount={data.discount} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Stats discount={data.discount} />
      </Grid>
    </Grid>
  );
}

function SummaryPanel({ discount }: { discount: Discount }) {
  return (
    <MainContentPanel>
      <div style={{ minHeight: boxHeight }}>
        <Typography variant="h6" gutterBottom>
          Sammendrag
        </Typography>
        <Summary discount={discount} />
      </div>
    </MainContentPanel>
  );
}

function Usage({ id }) {
  const { loading, data } = useQuery<{ licenses: LicenseOverviewModel[] }>(GET_USAGES, { variables: { id } });

  if (loading) return <LoadingSpinner />;

  return (
    <MainContentPanel>
      <Typography variant="h6">Brukes av</Typography>
      {data && (
        <>
          <Counter list={data.licenses} />
          <LicenseTable items={initialSettings} licenses={data.licenses} />
        </>
      )}
    </MainContentPanel>
  );
}

interface ActionsProps {
  discount: Discount;
}

function Actions({ discount }: ActionsProps) {
  const [openShare, setOpenShare] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditPrice, setOpenEditPrice] = useState(false);
  const [openEditEmailFilter, setOpenEditEmailFilter] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [deactivateDiscount] = useMutation(DEACTIVATE_DISCOUNT);
  const handleDeactivateDiscount = () => deactivateDiscount({ variables: { input: { id: discount.id } } });

  const hasShareableLink = discount.emailFilter == null;
  const hasPrice = discount.type === DiscountType.Fixed;

  return (
    <>
      <MainContentPanel>
        <div style={{ minHeight: boxHeight }}>
          <Typography variant="h6" gutterBottom>
            Handlinger
          </Typography>
          {hasShareableLink && (
            <div>
              <Button
                disabled={!discount.isApplicable}
                variant="contained"
                color="secondary"
                sx={{
                  margin: 1,
                  width: 250,
                }}
                onClick={() => setOpenShare(true)}
                startIcon={<Share />}>
                Få delbar kobling
              </Button>
            </div>
          )}
          <div>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                margin: 1,
                width: 250,
              }}
              onClick={() => setOpenEdit(true)}
              startIcon={<Edit />}>
              Endre bruksgrenser
            </Button>
          </div>
          {hasPrice && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  margin: 1,
                  width: 250,
                }}
                onClick={() => setOpenEditPrice(true)}
                startIcon={<LocalOffer />}>
                Endre pris
              </Button>
            </div>
          )}
          {discount.emailFilter && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  margin: 1,
                  width: 250,
                }}
                onClick={() => setOpenEditEmailFilter(true)}
                startIcon={<Mail />}>
                Endre e-post-filter
              </Button>
            </div>
          )}
          {discount.isActive && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  margin: 1,
                  width: 250,
                }}
                onClick={handleDeactivateDiscount}
                startIcon={<Block />}>
                Deaktiver
              </Button>
            </div>
          )}
          <div>
            <Button
              disabled={discount.usages.length > 0}
              variant="contained"
              color="secondary"
              sx={{
                margin: 1,
                width: 250,
              }}
              onClick={() => setOpenConfirm(true)}
              startIcon={<Delete />}>
              Slett
            </Button>
          </div>
        </div>
      </MainContentPanel>
      <DeleteDialog open={openConfirm} onClose={() => setOpenConfirm(false)} discount={discount} />
      {openShare && <ShareDialog onClose={() => setOpenShare(false)} discount={discount} />}
      <EditDialog open={openEdit} onClose={() => setOpenEdit(false)} discount={discount} />
      <EditPriceDialog open={openEditPrice} onClose={() => setOpenEditPrice(false)} discount={discount} />
      <EditEmailFilterDialog open={openEditEmailFilter} onClose={() => setOpenEditEmailFilter(false)} discount={discount} />
    </>
  );
}

interface EditProps {
  open: boolean;
  onClose(): void;
  discount: Discount;
}

function EditDialog({ open, onClose, discount }: EditProps) {
  const [maxUsageCount, setMaxUsageCount] = useState(discount.maxUsageCount);
  const [validFrom, setValidFrom] = useState(moment(discount.validFrom));
  const [validTo, setValidTo] = useState<moment.Moment | null>(discount.validTo ? moment(discount.validTo) : null);
  const [updateLimitations] = useMutation(UPDATE_DISCOUNT, {
    variables: { input: { id: discount.id, maxUsageCount, validFrom: validFrom.toDate(), validTo: validTo ? validTo.toDate() : null } },
  });

  const onMaxValueChanged = (value: string) => {
    if (!value) setMaxUsageCount(null);
    else setMaxUsageCount(Number(value));
  };

  const onCommit = () => {
    updateLimitations();
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">
        Endre bruksgrenser
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
          size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Stack spacing={2}>
            <TextField
              label="Totalt antall kunder som kan bruke rabatten"
              fullWidth
              type="number"
              value={maxUsageCount ?? ''}
              onChange={(ev) => onMaxValueChanged(ev.target.value)}
              margin="normal"
            />
            <DateTimePicker
              label="Startdato"
              value={validFrom}
              onChange={(newValue) => {
                if (newValue) setValidFrom(newValue);
              }}
            />

            <DateTimePicker label="Sluttdato" value={validTo} onChange={setValidTo} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} color="primary" variant="contained">
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface EditPriceProps {
  open: boolean;
  onClose(): void;
  discount: Discount;
}

function EditPriceDialog({ open, onClose, discount }: EditPriceProps) {
  const defaultTimeOfChange = moment().add(1, 'years').startOf('year');
  const [timeOfChange, setTimeOfChange] = useState(defaultTimeOfChange);
  const [price, setPrice] = useState(discount.value);
  const [updateValue] = useMutation(UPDATE_VALUE, { variables: { input: { id: discount.id, value: price, timeOfChange } } });

  const onPriceChanged = (value: number) => {
    setPrice(value);
  };

  const onCommit = () => {
    updateValue();
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">
        Endre pris
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
          size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <div
            style={{
              background: 'rgb(232, 244, 253)',
              borderRadius: 4,
              padding: 5,
              marginBottom: 25,
            }}>
            <Typography>Når skal endringen gjelde fra?</Typography>
            <div>
              <DatePicker
                disablePast
                label="dato"
                value={timeOfChange}
                onChange={(newValue) => {
                  if (newValue) setTimeOfChange(newValue);
                }}
              />
              <TimePicker
                sx={{ marginLeft: 2 }}
                ampm={false}
                label="tidspunkt"
                value={timeOfChange}
                onChange={(newValue) => {
                  if (newValue) setTimeOfChange(newValue);
                }}
              />
            </div>
          </div>
          <TextField
            variant="outlined"
            placeholder="kr"
            label="Beløp"
            helperText="Beløp pr tilgang"
            type="number"
            margin="normal"
            required
            value={price}
            onChange={(ev) => onPriceChanged(Number(ev.target.value))}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} color="primary" variant="contained">
          Endre pris
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteDialog({ open, onClose, discount }) {
  const [deleteDiscount, { called, loading }] = useMutation(DELETE_DISCOUNT, {
    update(cache, { data: { deleteDiscount } }) {
      const data = cache.readQuery<{ discounts: DiscountOverview[] }>({ query: GET_DISCOUNTS });
      if (data === null) return;
      cache.writeQuery({
        query: GET_DISCOUNTS,
        data: { discounts: data.discounts.filter((x) => x.id !== deleteDiscount) },
      });
    },
  });
  const handleDeleteDiscount = () => deleteDiscount({ variables: { input: { id: discount.id } } });

  if (called && !loading) return <Redirect to={'/discount'} />;

  return (
    <ConfirmDialog
      title="Slett rabatt"
      message="Vil du slette rabatten?"
      open={open}
      confirm={handleDeleteDiscount}
      deny={onClose}
      confirmText="Slett"
      DialogProps={{ fullWidth: true, maxWidth: 'xs' }}
    />
  );
}

function ShareDialog({ onClose, discount }) {
  const config = useConfig();
  const { data } = useQuery<{ discountToken: { id: string } }>(GET_DISCOUNT_TOKEN, { variables: { id: discount.id } });
  const [isBVNChecked, setIsBVNChecked] = useState(false);

  const url = buildDiscountUrl(config, discount, data, isBVNChecked);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [inputPageId, setInputPageId] = useState('');

  const handleInputPageId = (e: ChangeEvent<HTMLInputElement>) => {
    setInputPageId(e.target.value);
  };

  const checkHandler = () => {
    setIsBVNChecked(!isBVNChecked);
  };

  const pageIdQuery = () => {
    if (inputPageId) {
      return `${url}&id=${inputPageId}`;
    }
    return `${url}`;
  };

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(pageIdQuery()).catch(function (err) {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Dialog onClose={onClose} aria-labelledby="dialog-title" open={true}>
        <DialogTitle id="dialog-title">
          Få en delbar kobling
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 1,
              top: 1,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={onClose}
            size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Du kan dele denne rabattkoblingen med kundene dine via e-post eller sosiale medier. Denne rabatten blir brukt i kassen når kundene bruker
            denne koblingen.
          </DialogContentText>
          <TextField type="number" margin="dense" id="pageId" label="Side Id" onChange={handleInputPageId} value={inputPageId} />
          <div>
            <FormControlLabel control={<Checkbox checked={isBVNChecked} onChange={checkHandler} />} label="Huk av her om rabatt skal gjelde BVN" />
          </div>
          <TextField disabled fullWidth margin="dense" id="url" label="Url" value={pageIdQuery()} />
          <Button onClick={copyToClipboard} startIcon={<FileCopy />}>
            Kopier kobling
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Kobling kopiert</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)} size="large">
            <Close />
          </IconButton>,
        ]}
      />
    </>
  );
}

function buildDiscountUrl(
  config: Config | undefined,
  discount: Discount,
  tokenData: { discountToken: { id: string } } | undefined,
  isBVNChecked: boolean,
): string {
  if (!config || !tokenData) return '';

  const specificSubscriptions = discount.subscriptions.filter((x) => x.subscriptionId !== -1);
  const token = tokenData.discountToken?.id;
  if (specificSubscriptions.length === 1) {
    return `${config.bksUrl}/register/index?subscriptionId=${specificSubscriptions[0].subscriptionId}&discountToken=${token}`;
  }

  if (isBVNChecked === true) {
    return `${config.bksUrl}/subscription/index/3?discountToken=${token}`;
  }

  return `${config.bksUrl}/subscription?discountToken=${token}`;
}

function Stats({ discount }: { discount: Discount }) {
  const { data } = useQuery<{ orders: Order[] }>(GET_COMPLETED_ORDERS_WITH_DISCOUNT, { variables: { id: discount.id } });
  const OrderCont = ({ fromDate }: { fromDate?: Moment }) => <span>{data ? GetNumberOfOrders(data.orders, fromDate) : null}</span>;

  return (
    <MainContentPanel>
      <div style={{ minHeight: boxHeight }}>
        <Typography variant="h6" gutterBottom>
          Statistikk
        </Typography>
        <Typography>Antall kjøp</Typography>
        <List dense sx={{ width: 240 }}>
          <ListItem>
            <ListItemText>Siste 30 dager:</ListItemText>
            <OrderCont fromDate={moment().add(-30, 'days')} />
          </ListItem>
          <ListItem>
            <ListItemText>Siste 12 måneder:</ListItemText>
            <OrderCont fromDate={moment().add(-12, 'months')} />
          </ListItem>
          <ListItem>
            <ListItemText>Totalt:</ListItemText>
            <OrderCont />
          </ListItem>
        </List>
      </div>
    </MainContentPanel>
  );
}

function GetNumberOfOrders(orders: Order[], fromDate?: Moment): number {
  if (!fromDate) return orders.length;
  return orders.filter((x) => fromDate.isBefore(x.startedAt)).length;
}

function EditEmailFilterDialog({ open, onClose, discount }: { discount: Discount; open: boolean; onClose(): void }) {
  const [filter, setFilter] = useState(discount.emailFilter);
  const [update, { loading }] = useMutation(UPDATE_EMAIL_FILTER, { variables: { input: { id: discount.id, emailFilter: filter } } });
  const handleClose = () => {
    setFilter(discount.emailFilter);
    onClose();
  };
  const handleCommit = async () => {
    await update();
    onClose();
  };
  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">
        Endre e-post-filter
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
          size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label="E-post-filter"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          margin="normal"
          multiline
          helperText="Oppgi enten ett e-post-domene (f.eks. stud.ntnu.no) eller en kommaseparert liste med e-postadresser"
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Avbryt
        </Button>
        <Button onClick={handleCommit} disabled={!filter || loading} color="primary" variant="contained">
          Endre filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}
