import { FC, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UPDATE_PRICE } from './subscriptions.graphql';
import moment from 'moment';
import { SubscriptionModel } from '../interfaces';
import { DateTimePicker } from '@mui/x-date-pickers';

export const UpdatePriceDialog: FC<{
  subscription: SubscriptionModel;
  onClose(): void;
  setBusy(busy: boolean): void;
}> = ({ subscription, onClose, setBusy }) => {
  const price = subscription.price;
  const defaultTimeOfChange = moment().add(1, 'years').startOf('year');

  const [singlePrice, setSinglePrice] = useState(price.singleUser);
  const [extraPrice, setExtraPrice] = useState(price.extraUser);
  const [timeOfChange, setTimeOfChange] = useState(defaultTimeOfChange);

  const [updateValue, { loading }] = useMutation(UPDATE_PRICE, {
    variables: { input: { subscriptionId: subscription.bksId, singleUser: singlePrice, extraUser: extraPrice, timeOfChange } },
  });

  useEffect(() => {
    setBusy(loading);
  }, [loading, setBusy]);

  if (!price) return null;

  const priceChanged = singlePrice !== price.singleUser || extraPrice !== price.extraUser;

  const onTimeOfChangeChanged = (newValue) => {
    if (newValue) setTimeOfChange(newValue);
    else setTimeOfChange(defaultTimeOfChange);
  };

  const onCommit = () => {
    updateValue();
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-title" open>
      <DialogTitle id="dialog-title">
        Endre pris
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
          size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <div
            style={{
              background: 'rgb(232, 244, 253)',
              borderRadius: 4,
              padding: 5,
              marginBottom: 25,
            }}>
            <Typography gutterBottom>Når skal endringen gjelde fra?</Typography>
            <DateTimePicker label="tidspunkt" value={timeOfChange} onChange={onTimeOfChangeChanged} />
          </div>
          <Grid2 container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid2>
              <TextField
                variant="outlined"
                slotProps={{ input: { startAdornment: <InputAdornment position="start">kr</InputAdornment> }, htmlInput: { min: 0 } }}
                label="1 bruker"
                helperText="Beløp for 1 bruker"
                type="number"
                margin="normal"
                required
                value={singlePrice}
                onChange={(ev) => setSinglePrice(Number(ev.target.value))}
              />
            </Grid2>
            <Grid2>
              <TextField
                variant="outlined"
                label="Ekstra brukere"
                helperText="Beløp pr ekstra bruker"
                slotProps={{ input: { startAdornment: <InputAdornment position="start">kr</InputAdornment> }, htmlInput: { min: 0 } }}
                type="number"
                margin="normal"
                required
                value={extraPrice}
                onChange={(ev) => setExtraPrice(Number(ev.target.value))}
              />
            </Grid2>
          </Grid2>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} disabled={!priceChanged} color="primary" variant="contained">
          Endre pris
        </Button>
      </DialogActions>
    </Dialog>
  );
};
