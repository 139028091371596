import { useState } from 'react';
import { Typography, Button, Snackbar, IconButton } from '@mui/material';
import { Close, FileCopyOutlined } from '@mui/icons-material';
import { MainContentPanel } from '../../components/MainContentPanel';
import { UserCard } from './UserCard';

import { UPDATE_NAME, UPDATE_EMAIL, UPDATE_PHONE, UPDATE_ADDRESS, GET_USER, GET_LAST_RESET_PASSWORD_URL, UPDATE_LOGIN } from '../user.graphql';
import { useConfig } from '../../components/Configuration';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import { User } from '../../interfaces';

interface Props {
  userId: number;
}

export function UserInfo({ userId }: Props) {
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const handleCloseSnackbar = () => setShowErrorSnackbar(false);

  const [userUpdateLoginId] = useMutation(UPDATE_LOGIN, { onError: () => setShowErrorSnackbar(true) });
  const [userUpdateName] = useMutation(UPDATE_NAME);
  const [userUpdateEmail] = useMutation(UPDATE_EMAIL, { onError: () => setShowErrorSnackbar(true) });
  const [userUpdatePhone] = useMutation(UPDATE_PHONE);
  const [userUpdateAddress] = useMutation(UPDATE_ADDRESS);

  const mutationInput = (parameters) => {
    return { variables: { input: parameters } };
  };
  const updateLoginId = (loginId) => {
    userUpdateLoginId(mutationInput({ id: userId, loginId }));
  };
  const updateName = (firstName, lastName) => {
    userUpdateName(mutationInput({ id: userId, firstName: firstName, lastName: lastName }));
  };
  const updateEmail = (email) => {
    userUpdateEmail(mutationInput({ id: userId, email: email }));
  };
  const updatePhone = (phone) => {
    userUpdatePhone(mutationInput({ id: userId, phone: phone }));
  };
  const updateAddress = (address) => {
    userUpdateAddress(mutationInput(address));
  };

  const { loading, error, data } = useQuery<{ user: User }>(GET_USER, { variables: { id: userId } });
  const resetPasswordUrlResult = useQuery<{ url: string }>(GET_LAST_RESET_PASSWORD_URL, { variables: { id: userId } });

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Detaljer
      </Typography>
      {error && <span>{error.message}</span>}
      {loading && <LoadingSpinner />}
      {data && (
        <>
          <UserCard
            user={data.user}
            onEditLoginId={updateLoginId}
            onEditAddress={updateAddress}
            onEditEmail={updateEmail}
            onEditPhone={updatePhone}
            onEditName={updateName}
            disableLink
          />
          <LoginAsUser username={data.user.loginId} />
          {resetPasswordUrlResult.data && resetPasswordUrlResult.data.url && <CopyLastResetPasswordUrl url={resetPasswordUrlResult.data.url} />}
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">E-posten er allerede brukt.</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleCloseSnackbar} size="large">
            <Close />
          </IconButton>,
        ]}
      />
    </MainContentPanel>
  );
}

function LoginAsUser({ username }: { username: string }) {
  const config = useConfig();
  if (!config) return null;
  return (
    <a
      style={{
        marginRight: 15,
        margin: 1,
      }}
      href={`${config.bksUrl}/Administration/LoginAsAnotherUser/${username}`}
      target="_blank"
      rel="noreferrer">
      Logg inn som bruker
    </a>
  );
}

function CopyLastResetPasswordUrl({ url }: { url: string }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).catch(function (err) {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
      setOpenSnackbar(true);
    }
  };
  return (
    <>
      <Button
        style={{
          marginRight: 15,
          margin: 1,
        }}
        variant="contained"
        color="secondary"
        onClick={copyToClipboard}>
        <FileCopyOutlined /> Kopiér lenke til nytt passord
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Lenken ble kopiert</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)} size="large">
            <Close />
          </IconButton>,
        ]}
      />
    </>
  );
}
