import { MainContentPanel } from '../components/MainContentPanel';
import { GET_LICENSES_WITH_HIGH_USAGE } from './reports.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import { Grid, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { WidgetTitle } from '../dashboard/WidgetTitle';
import { useState } from 'react';

interface License {
  licenseId: string;
  companyName: string;
  orgNumber: string;
  views: string;
}

export function LicensePageViews(props) {
  const [monthOffset, setMonthOffset] = useState(Number(props.offset));

  var take = 100;

  const { loading, data } = useQuery(GET_LICENSES_WITH_HIGH_USAGE, {
    variables: { monthOffset: monthOffset, take: take },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <WidgetTitle
            title="Kundeaktivitet for"
            offset={monthOffset}
            stateSetter={(step) => {
              setMonthOffset(monthOffset + step);
            }}
            displayYear={true}
            forwardLimit={1}
          />

          {loading && <LoadingSpinner />}
          {!loading && !data && <div>Mangler data</div>}
          {data && renderDetails(data.licenses)}
        </Grid>
      </Grid>
    </>
  );

  function renderDetails(licenses) {
    return (
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell align="right">Antall sidevisninger</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {licenses.map((license: License) => {
            return (
              <TableRow key={license.licenseId}>
                <TableCell>
                  <Link to={`/license/${license.licenseId}`}>{license.companyName}</Link>
                </TableCell>
                <TableCell align="right">{license.views}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}
