import { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import { MainContentPanel } from '../components/MainContentPanel';
import { ChurnChart } from './churn';
import { TerminationReport } from './TerminationReport';
import BrregSubMenu from './brreg';
import { LicensePageViews } from './LicensePageViews';
import { UserPageViews } from './UserPageViews';
import { NewSubscriptions } from './NewSubscriptions';
import { useLocation } from 'react-router-dom';
import UsageOverview from './guides/UsageOverview';
import { UserOccupation } from './UserOccupation';

export function Overview() {
  let searchParms = new URLSearchParams(useLocation().search);
  let tabFromUrl = searchParms.get('tab') ?? '0';

  var monthOffset = searchParms.get('offset') ?? '-1';

  const [tab, setTab] = useState(Number(tabFromUrl));

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Rapporter
      </Typography>

      <Tabs value={tab} onChange={(_, val) => setTab(val)} style={{ marginTop: '35px', marginLeft: '0px' }}>
        <Tab label="Churn" />
        <Tab label="Oppsigelser" />
        {/* <Tab label="Bruksrapport" /> */}
        <Tab label="Brreg" />
        <Tab label="Kundeaktivitet" />
        <Tab label="Brukeraktivitet" />
        <Tab label="Nye kunder" />
        <Tab label="Bruksrapport" />
        <Tab label="Yrke" />
      </Tabs>
      <Box marginTop={4}>
        {tab === 0 && <ChurnChart />}
        {tab === 1 && <TerminationReport />}
        {tab === 2 && <BrregSubMenu />}
        {tab === 3 && <LicensePageViews offset={monthOffset} />}
        {tab === 4 && <UserPageViews offset={monthOffset} />}
        {tab === 5 && <NewSubscriptions offset={monthOffset} />}
        {tab === 6 && <UsageOverview />}
        {tab === 7 && <UserOccupation />}
      </Box>
    </MainContentPanel>
  );
}
