import React, { useState } from 'react';
import { TextField, Typography, Box } from '@mui/material';

const ExtraUser = ({ initialValue, onChange, disabled }) => {
  const [value, setValue] = useState(initialValue);
  if (initialValue !== value) setValue(initialValue);
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (parseInt(newValue) >= 0 && parseInt(newValue) <= 200)) {
      setValue(newValue);
      onChange(newValue === '' ? '' : parseInt(newValue)); // Pass the new value back to the parent component
    }
  };

  const isError = !disabled && (parseInt(value) < 1 || parseInt(value) > 200 || value === '');

  return (
    <div>
      <Box
        border={1}
        borderColor="grey.500"
        p={2}
        borderRadius={2}
        sx={{ border: isError ? '1px solid red' : undefined, color: isError ? undefined : '#717171' }}>
        <Typography gutterBottom>Extra Percentage</Typography>
        <TextField
          type="number"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          fullWidth
          variant="outlined"
          margin="dense"
          inputProps={{ min: 1, max: 200 }}
        />
      </Box>
    </div>
  );
};

export default ExtraUser;
