import { TableCell, TableRow } from '@mui/material';
import { RequiredClaim } from '../../interfaces';
interface Prop {
  requiredClaim: RequiredClaim;
}
export default function ClaimBox(props: Prop) {
  const { requiredClaim } = props;
  return (
    <>
      <TableRow sx={{ paddingLeft: 22 }}>
        <TableCell>{requiredClaim.type}</TableCell>
        <TableCell sx={{ paddingLeft: 22 }}>{requiredClaim.value}</TableCell>
      </TableRow>
    </>
  );
}
