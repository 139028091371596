import { FC } from 'react';
import { IconButton, Avatar, Toolbar, AppBar, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { useConfig } from '../components/Configuration';
import { useAuth } from 'oidc-react';
import { IdTokenClaims } from 'oidc-client-ts';
import gravatarUrl from 'gravatar-url';

const drawerWidth = 240;

interface TopHeaderProps {
  handleDrawerToggle(): void;
}

export const TopHeader: FC<TopHeaderProps> = ({ handleDrawerToggle }) => {
  const auth = useAuth();
  const config = useConfig();
  const initials = getInitials(auth.userData?.profile);
  const email = auth.userData?.profile?.email;

  const url = email ? gravatarUrl(email, { size: 200, default: '404' }) : undefined;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}>
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }} size="large">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" sx={{ flex: 1 }} noWrap>
          Salg
        </Typography>
        {config && <EnvironmentDisplay environment={config.env} />}
        {config && (
          <Typography sx={{ marginRight: 15 }} variant="caption" color="inherit">
            v{config.version}
          </Typography>
        )}
        <Avatar src={url}>{initials}</Avatar>
      </Toolbar>
    </AppBar>
  );
};

function EnvironmentDisplay({ environment }) {
  const EnvTypograpy = ({ children }) => (
    <Typography variant="h3" color="inherit" sx={{ marginRight: 100 }}>
      {children}
    </Typography>
  );

  if (environment === 'Dev') return <EnvTypograpy>DEV</EnvTypograpy>;
  if (environment === 'test') return <EnvTypograpy>TEST</EnvTypograpy>;
  if (environment === 'staging') return <EnvTypograpy>BETA</EnvTypograpy>;

  return null;
}

const getInitials = (profile?: IdTokenClaims) => {
  if (!profile || !profile.given_name?.length || !profile.family_name?.length) return '';
  return `${profile.given_name[0].toUpperCase()}${profile.family_name[0].toUpperCase()}`;
};
