import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, ListItemButton, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessIcon from '@mui/icons-material/Business';
import DocumentOverview from './DocumentOverview';
import CompanyOverview from './CompanyOverview';

// Define Overview component using React and TypeScript
function UsageOverview() {
  // Define state to keep track of which overview to display
  const [selectedOverview, setSelectedOverview] = React.useState('usageOverview');

  // Define function to handle link clicks
  const handleLinkClick = (overview: string) => {
    setSelectedOverview(overview);
  };

  return (
    <>
      {selectedOverview === 'usageOverview' && (
        <Grid container spacing={2}>
          <Grid item>
            <Link component={RouterLink} to="#" color="inherit" style={{ textDecoration: 'none' }} onClick={() => handleLinkClick('document')}>
              <ListItemButton sx={{ padding: '12px 16px' }}>
                <DescriptionIcon sx={{ color: '#757575' }} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#287891',
                    marginLeft: '10px',
                  }}>
                  Per Dokument
                </Typography>
              </ListItemButton>
            </Link>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="#" color="inherit" style={{ textDecoration: 'none' }} onClick={() => handleLinkClick('company')}>
              <ListItemButton sx={{ padding: '12px 16px' }}>
                <BusinessIcon sx={{ color: '#757575' }} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#287891',
                    marginLeft: '10px',
                  }}>
                  Per Firma
                </Typography>
              </ListItemButton>
            </Link>
          </Grid>
        </Grid>
      )}
      {selectedOverview === 'document' && <DocumentOverview onBackClick={() => setSelectedOverview('usageOverview')} />}
      {selectedOverview === 'company' && <CompanyOverview onBackClick={() => setSelectedOverview('usageOverview')} />}
    </>
  );
}

export default UsageOverview;
