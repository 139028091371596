import { CircularProgress, TableHead, Table, TableRow, TableCell, TableBody, Grid2 } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_USER_OCCUPATION } from './reports.graphql';
import { UserModel } from '../interfaces';
import { GeneralExcelExport, GeneralExcelExportProps } from './brreg';

export function UserOccupation() {
  const { loading, data } = useQuery<{ userOccupation: UserModel[] }>(GET_USER_OCCUPATION);
  if (loading || !data) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  const groupedData = groupByOccupation(data.userOccupation);

  const arrHeading: string[] = ['Yrke', 'Antall bruker'];
  const dataSet = groupedData && groupedData.map((x) => [x.occupation, x.count]);
  const workBook = 'BRUKER_ANTALL_PER_YRKE';
  const fileName = 'brukerantall.xlsx';
  const props: GeneralExcelExportProps = {
    dataSet: JSON.stringify(dataSet),
    headings: JSON.stringify(arrHeading),
    workBookName: workBook,
    fileName: fileName,
  };
  return (
    <Grid2 container>
      <Grid2>
        {groupedData && <GeneralExcelExport {...props} />}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Yrke</TableCell>
              <TableCell>Antall brukere</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedData
              .sort((a, b) => a.occupation.localeCompare(b.occupation))
              .map(({ occupation, count }) => (
                <TableRow key={occupation}>
                  <TableCell>{occupation}</TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid2>
    </Grid2>
  );
}
function groupByOccupation(data: UserModel[]) {
  const groupedData = data.reduce(
    (groups, user) => {
      const { occupation } = user;
      if (!groups[occupation]) {
        groups[occupation] = {
          occupation,
          count: 0,
        };
      }
      groups[occupation].count++;
      return groups;
    },
    {} as Record<string, { occupation: string; count: number }>,
  );
  return Object.values(groupedData);
}
