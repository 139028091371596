import { createRoot } from 'react-dom/client';

import App from './App';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './Theme';
import getConfig from './config';
import { AuthProvider } from 'oidc-react';
import 'moment/dist/locale/nb';
import log from 'loglevel';
import { LicenseInfo } from '@mui/x-license';
import { getOidcConfig } from './authentication/oidc';
import { CustomApolloProvider } from './apollo';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

LicenseInfo.setLicenseKey('d6b9a3909ff8d97f2bef557807a0f6d9Tz04NTI1NyxFPTE3NDA3NDY1MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

async function initialize() {
  const config = await getConfig();
  const oidcConfig = getOidcConfig(config);

  log.setDefaultLevel('debug');

  function ThemedApp() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthProvider {...oidcConfig}>
            <CustomApolloProvider config={config} oidcConfig={oidcConfig}>
              <App />
            </CustomApolloProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<ThemedApp />);
}

initialize();
