import React, { FC, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid2,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import { MainContentPanel } from '../components/MainContentPanel';
import { formatDate, numberAsKr } from '../Formatters';
import { CANCEL_PRICE_UPDATE, GET_CHANGES, GET_SUBSCRIPTIONS } from './subscriptions.graphql';
import { Alert } from '@mui/material';
import { ChangeLog } from '../components/ChangeLog';
import { useConfig } from '../components/Configuration';
import { Change, SubscriptionModel, SubscriptionPrice } from '../interfaces';
import { UpdatePriceDialog } from './UpdatePriceDialog';
import { PdfPriceCard } from '../pdf/overview';

export const Overview: FC = () => {
  return (
    <MainContentPanel>
      <Stack spacing={5}>
        <SubscriptionsCard />
        <PdfPriceCard />
        <Divider />
        <ChangeLogCard />
      </Stack>
    </MainContentPanel>
  );
};

const SubscriptionsCard: FC = () => {
  const { loading, data } = useQuery<{ subscriptions: SubscriptionModel[] }>(GET_SUBSCRIPTIONS);

  return (
    <Stack>
      <Typography variant="h5" gutterBottom>
        Abonnement
      </Typography>
      {loading && <LoadingSpinner />}
      {data && <SubscriptionTable subscriptions={data.subscriptions} />}
    </Stack>
  );
};

function SubscriptionTable({ subscriptions }: { subscriptions: SubscriptionModel[] }) {
  return (
    <Grid2 container>
      <Grid2>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={3} align="center">
                Priser
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell align="right">1 bruker</TableCell>
              <TableCell align="right">Ekstra brukere</TableCell>
              <TableCell style={{ width: 355 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((x) => (
              <TableRow key={x.id}>
                <SubscriptionItem subscription={x} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid2>
    </Grid2>
  );
}

function SubscriptionItem({ subscription }: { subscription: SubscriptionModel }) {
  return (
    <>
      <TableCell>{subscription.name}</TableCell>
      <TableCell align="right">
        <Price subscription={subscription} priceGetter={(p) => p.singleUser} newPriceGetter={(p) => p.singleUser} />
      </TableCell>
      <TableCell align="right">
        <Price subscription={subscription} priceGetter={(p) => p.extraUser} newPriceGetter={(p) => p.extraUser} />
      </TableCell>
      <TableCell>
        <SubscriptionAction subscription={subscription} />
      </TableCell>
    </>
  );
}

function Price({
  subscription,
  priceGetter,
  newPriceGetter,
}: {
  subscription: SubscriptionModel;
  priceGetter(p: SubscriptionPrice): number;
  newPriceGetter(p: SubscriptionPrice): number;
}) {
  const price = subscription.price;
  if (!price) return null;
  if (!subscription.pendingPriceChange) return <span>{numberAsKr(priceGetter(price))}</span>;
  return (
    <>
      <OldPriceSpan>{numberAsKr(priceGetter(price))}</OldPriceSpan>
      <NewPriceSpan>{numberAsKr(newPriceGetter(subscription.pendingPriceChange.newPrice))}</NewPriceSpan>
    </>
  );
}

const OldPriceSpan = styled('span')(({ theme }) => ({
  textDecoration: 'line-through',
  color: theme.palette.error.main,
}));

const NewPriceSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const SubscriptionAction: FC<{ subscription: SubscriptionModel }> = ({ subscription }) => {
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [cancelUpdate, { loading }] = useMutation(CANCEL_PRICE_UPDATE, { variables: { input: { subscriptionId: subscription.bksId } } });
  if (!subscription.price) return null;

  if (subscription.pendingPriceChange) {
    return (
      <Stack direction="row" alignItems="center">
        <div>
          <IconButton disabled={loading} title="Forkast endring" onClick={() => cancelUpdate()} size="small">
            <Delete fontSize="small" />
          </IconButton>
        </div>
        <Alert
          sx={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            pt: 0,
            pb: 0,
            '& .MuiAlert-message': {
              padding: 0,
            },
            '& .MuiAlert-icon': {
              padding: 0,
            },
          }}
          severity="info">
          Prisen vil bli oppdatert {formatDate(subscription.pendingPriceChange.timeOfChange)}
        </Alert>
      </Stack>
    );
  } else {
    return (
      <>
        <IconButton disabled={busy} title="Oppdater prisen" onClick={() => setOpen(true)} size="small">
          <Edit fontSize="small" />
        </IconButton>
        {open && <UpdatePriceDialog subscription={subscription} onClose={() => setOpen(false)} setBusy={setBusy} />}
        {busy && (
          <CircularProgress
            sx={{
              verticalAlign: `middle`,
            }}
            size={30}
          />
        )}
      </>
    );
  }
};

function ChangeLogCard() {
  const config = useConfig();
  const [limit, setLimit] = useState(5);
  const pollIntervall = config ? config.defaultPollingInterval : 0;

  const { loading, data, refetch } = useQuery<{ queryResult: { totalCount: number; changes: Change[] } }>(GET_CHANGES, {
    variables: { limit },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data && data.queryResult.changes && data.queryResult.changes.length < data.queryResult.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };

  return (
    <Stack>
      <Typography variant="h5" gutterBottom>
        Endringer
      </Typography>
      {(!data || loading) && <LoadingSpinner />}
      <Grid2 container>
        <Grid2>
          {data && <ChangeLog changes={data.queryResult.changes} />}
          {moreToLoad && (
            <Button disabled={loading} onClick={doFetchMore}>
              Last flere
            </Button>
          )}
        </Grid2>
      </Grid2>
    </Stack>
  );
}
