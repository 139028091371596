import React, { FC, useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Select, MenuItem, FormControl, IconButton, TextField, Grid2, Box } from '@mui/material';
import { ScatterChart, Scatter, XAxis, YAxis, ResponsiveContainer, Tooltip, Label } from 'recharts';
import { useQuery } from '@apollo/client';
import { GET_CHURN } from './reports.graphql';
import LoadingSpinner from '../components/LoadingSpinner';
import { LensOutlined, Lens } from '@mui/icons-material';
import { numberAsKr, formatDateOnly } from '../Formatters';
import XLSX from 'xlsx';
import ExcelLogo from './../assets/microsoft-excel.svg?react';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface ChurnPlot {
  timestamp: number;
  value: number;
}
enum ChurnType {
  Amount,
  Number,
}
enum ChurnAction {
  All,
  Increase,
  Decrease,
}

export const TimeRange: FC<{ from: moment.Moment | null; setFrom(val: any): void; to: moment.Moment | null; setTo(value: any): void }> = ({
  from,
  setFrom,
  to,
  setTo,
}) => {
  return (
    <>
      <DatePicker disableFuture label="Fra dato" value={from} onChange={setFrom} />
      <DatePicker disableFuture label="Til dato" value={to} onChange={setTo} />
    </>
  );
};
function InvoiceMonth({ month, setMonth }) {
  const onChange = ({ target: { value } }) => {
    if (value === '') {
      setMonth(undefined);
      return;
    }
    setMonth(value);
  };
  return (
    <TextField select label="Fakturamåned" value={month ? month : ''} onChange={onChange} sx={{ minWidth: 150 }}>
      <MenuItem>
        <em>Alle</em>
      </MenuItem>
      <MenuItem value={1}>januar</MenuItem>
      <MenuItem value={2}>februar</MenuItem>
      <MenuItem value={3}>mars</MenuItem>
      <MenuItem value={4}>april</MenuItem>
      <MenuItem value={5}>mai</MenuItem>
      <MenuItem value={6}>juni</MenuItem>
      <MenuItem value={7}>juli</MenuItem>
      <MenuItem value={8}>august</MenuItem>
      <MenuItem value={9}>september</MenuItem>
      <MenuItem value={10}>oktober</MenuItem>
      <MenuItem value={11}>november</MenuItem>
      <MenuItem value={12}>desember</MenuItem>
    </TextField>
  );
}
function TypeSelect({ type, setType }) {
  const onChange = ({ target: { value } }) => {
    setType(value);
  };
  return (
    <TextField select label="Visning" value={type} onChange={onChange}>
      <MenuItem value={ChurnType.Amount}>{typeToString(ChurnType.Amount)}</MenuItem>
      <MenuItem value={ChurnType.Number}>{typeToString(ChurnType.Number)}</MenuItem>
    </TextField>
  );
}
function ActionsSelect({ actions, setActions }) {
  const onChange = ({ target: { value } }) => {
    setActions(value);
  };
  return (
    <TextField select label="Type handling" value={actions} onChange={onChange} sx={{ minWidth: 125 }}>
      <MenuItem value={ChurnAction.All}>Alle</MenuItem>
      <MenuItem value={ChurnAction.Increase}>Økning</MenuItem>
      <MenuItem value={ChurnAction.Decrease}>Reduksjon</MenuItem>
    </TextField>
  );
}
export function ChurnChart() {
  const startDate = moment().startOf('year');
  const [from, setFrom] = useState<moment.Moment | null>(startDate);
  const [to, setTo] = useState<moment.Moment | null>(null);
  const [type, setType] = useState(ChurnType.Amount);
  const [actions, setActions] = useState(ChurnAction.All);

  const [month, setMonth] = useState<number | undefined>();
  const [defs, setDefs] = useState([
    { key: 'bfsAll', sortIndex: 1, name: 'Bfs', color: 'black', visible: true },
    { key: 'bfs', sortIndex: 2, name: 'Komplett', color: '#7D5C65', visible: false },
    { key: 'bfsp', sortIndex: 3, name: 'Planlegging', color: '#52823b', visible: false },
    { key: 'bfsb', sortIndex: 4, name: 'Byggdetaljer', color: '#287891', visible: false },
    { key: 'bfsf', sortIndex: 5, name: 'Forvaltning', color: '#81710a', visible: false },
    { key: 'bfsu', sortIndex: 6, name: 'Utførelse', color: '#DCEDC8', visible: false },
    { key: 'bvn', sortIndex: 7, name: 'Bvn', color: '#82d0f5', visible: true },
  ]);
  const toggleVisible = (key: string) => {
    const item = defs.find((x) => x.key === key);
    if (!item) return;
    const newItem: PlotDef = { ...item, visible: !item.visible } as PlotDef;

    setDefs(
      defs
        .filter((x) => x !== item)
        .concat([newItem])
        .sort((x, y) => x.sortIndex - y.sortIndex),
    );
  };
  const formatTooltip = (value, name) => {
    if (name === 'Dato') return formatDateOnly(value);
    if (name === typeToString(ChurnType.Amount)) return numberFormatedToType(value, ChurnType.Amount);
    if (name === typeToString(ChurnType.Number)) return numberFormatedToType(value, ChurnType.Number);

    return value;
  };
  const needExtraSpace = type === ChurnType.Amount;
  const { loading, data } = useQuery<{ model: ChurnModel }>(GET_CHURN, {
    variables: { fromDate: from, toDate: to, invoiceMonth: month, type, actions },
  });
  return (
    <>
      <Grid2 container direction="row" spacing={3} alignItems="center">
        <Grid2>
          <TypeSelect type={type} setType={setType} />
        </Grid2>
        <Grid2>
          <ActionsSelect actions={actions} setActions={setActions} />
        </Grid2>
        <Grid2>
          <InvoiceMonth month={month} setMonth={setMonth} />
        </Grid2>
        <Grid2>
          <DatePicker disableFuture label="Fra dato" value={from} onChange={setFrom} />
        </Grid2>
        <Grid2>
          <DatePicker disableFuture label="Til dato" value={to} onChange={setTo} />
        </Grid2>
        <Grid2>{data && <ExcelExport defs={defs} model={data.model} />}</Grid2>
        <Grid2>{data && <SubscriptionSelector plotDefs={defs} model={data.model} toggleVisible={toggleVisible} />}</Grid2>
      </Grid2>
      {loading && <LoadingSpinner />}
      {data && (
        <ResponsiveContainer width="80%" height={700}>
          <ScatterChart margin={{ top: 25, right: 5, left: needExtraSpace ? 100 : 25, bottom: 25 }}>
            <YAxis
              type="number"
              dataKey={'value'}
              name={typeToString(type)}
              domain={['auto', 'auto']}
              tickFormatter={(tick, _) => (tick ? numberFormatedToType(tick, type) : '')}>
              <Label value={typeToString(type)} position="left" angle={-90} offset={needExtraSpace ? 75 : 5} />
            </YAxis>
            <XAxis
              dataKey={'timestamp'}
              type="number"
              scale="time"
              tickFormatter={(tick) => formatDateOnly(tick)}
              domain={[Math.min(...data.model.bfs.map((x) => x.timestamp)), Math.max(...data.model.bfs.map((x) => x.timestamp))]}
              name="Dato">
              <Label value="Dato" position="bottom" />
            </XAxis>
            {defs
              .filter((x) => x.visible)
              .map((d) => (
                <Scatter key={d.key} name={d.name} data={data.model[d.key]} fill={d.color} line={{ stroke: d.color, strokeWidth: 2 }} />
              ))}
            <Tooltip formatter={formatTooltip} />
          </ScatterChart>
        </ResponsiveContainer>
      )}
    </>
  );
}
interface PlotDef {
  key: string;
  sortIndex: number;
  name: string;
  color: string;
  visible: boolean;
}

function SubscriptionSelector({ plotDefs, model, toggleVisible }: { plotDefs: PlotDef[]; model: ChurnModel; toggleVisible(key: string): void }) {
  return (
    <FormGroup row>
      {plotDefs.map((d) => (
        <FormControlLabel
          key={d.key}
          control={
            <Checkbox
              checked={d.visible}
              onChange={() => toggleVisible(d.key)}
              name={d.key}
              icon={<LensOutlined style={{ color: d.color }} />}
              checkedIcon={<Lens style={{ color: d.color }} />}
            />
          }
          label={<SubscriptionLabel def={d} model={model} />}
        />
      ))}
    </FormGroup>
  );
}
function SubscriptionLabel({ def, model }: { def: PlotDef; model: ChurnModel }) {
  const list: ChurnPlot[] = model[def.key];
  if (!def.visible || list.length < 2) return <span>{def.name}</span>;
  const initial = list[0].value;
  const final = list[list.length - 1].value;
  const percentage = ((initial - final) / initial) * -1;
  return (
    <span>
      {def.name} (
      <span style={{ color: percentage < 0 ? 'red' : undefined }}>
        {percentage.toLocaleString('nb-NO', { style: 'percent', maximumFractionDigits: 1 })}
      </span>
      )
    </span>
  );
}
function ExcelExport({ defs, model }: { defs: PlotDef[]; model: ChurnModel }) {
  const exportFile = () => {
    const workbook = XLSX.utils.book_new();
    defs
      .filter((x) => x.visible)
      .forEach((def) => {
        const headings: string[] = ['Dato', 'Verdi'];
        const data = model[def.key].map((plot) => [new Date(plot.timestamp), plot.value]);
        const input = [headings, ...data];
        const sheet = XLSX.utils.aoa_to_sheet(input);
        XLSX.utils.book_append_sheet(workbook, sheet, def.name);
      });
    XLSX.writeFile(workbook, 'churn.xlsx');
  };
  return (
    <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
      <ExcelLogo
        style={{
          width: 24,
          height: 24,
        }}
      />
    </IconButton>
  );
}

function typeToString(type: ChurnType): string {
  if (type === ChurnType.Amount) return 'Beløp';
  if (type === ChurnType.Number) return 'Antall';

  return 'Ukjent';
}
function numberFormatedToType(value: number, type: ChurnType): string {
  if (type === ChurnType.Amount) return numberAsKr(value) ?? value.toString();
  if (type === ChurnType.Number) return value.toLocaleString('nb-NO');

  return 'Ukjent';
}
interface ChurnModel {
  bfsAll: ChurnPlot[];
  bfs: ChurnPlot[];
  bfsp: ChurnPlot[];
  bfsb: ChurnPlot[];
  bfsf: ChurnPlot[];
  bfsu: ChurnPlot[];
  bvn: ChurnPlot[];
}
