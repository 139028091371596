import React, { Component, FC } from 'react';
import DashboardOverview from './dashboard/Overview';
import UserOverview from './users/overview/Overview';
import UserDetails from './users/details/Details';
import ADFSSubSettings from './administration/ADFSConfig/ADFSSubSettings';
import { LicenseOverview } from './licenses/overview/Overview';
import LicenseDetails from './licenses/details/Details';
import TaskOverview, { IncompleteTasksBadgeContent } from './tasks/overview/Overview';
import TaskIcon from '@mui/icons-material/Assignment';
import OrderOverview from './orders/overview/Overview';
import { RouteDef, Shell } from './shell/Shell';
import { Folders } from './discounts/overview/Folder';
import { Discounts } from './discounts/overview/overview';
import { DiscountDetails } from './discounts/details/Details';
import { NewDiscount } from './discounts/overview/NewDiscount';
import { RenewalOverview } from './renewals/Overview';
import { Overview as ReportOverview } from './reports/Overview';
import { Overview as SubscriptionOverview } from './subscriptions/overview';
import { Overview as Administration } from './administration/overview';
import { Dashboard, Group, Business, ShoppingBasket, NewReleases, Sync, TrendingDown, LocalOffer, Settings } from '@mui/icons-material';
import GeneralSettings from './administration/ADFSConfig/GeneralSettings';
import AdvanceSettings from './administration/ADFSConfig/AdvanceSettings';
import ADFSClientAuthorization from './administration/ADFSConfig/ADFSClientAuthorization';
import CompanyDetail from './reports/guides/CompanyDetail';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const routes: RouteDef[] = [
  { path: '/', component: DashboardOverview, exact: true, title: 'Dashboard', icon: <Dashboard /> },
  { path: '/license', component: LicenseOverview, exact: true, title: 'Kunder', icon: <Business /> },
  { path: '/license/:id', component: LicenseDetails },
  { path: '/adfssubsetting/:bksID', component: ADFSSubSettings },
  { path: '/authorization/', component: ADFSClientAuthorization },
  { path: '/advancesetting/', component: AdvanceSettings },
  { path: '/generalsetting', component: GeneralSettings },
  { path: '/user', component: UserOverview, exact: true, title: 'Brukere', icon: <Group /> },
  { path: '/user/:id', component: UserDetails },
  {
    path: '/task',
    component: TaskOverview,
    exact: true,
    title: 'Oppgaver',
    icon: (
      <IncompleteTasksBadgeContent>
        <TaskIcon />
      </IncompleteTasksBadgeContent>
    ),
  },
  { path: '/order', component: OrderOverview, exact: true, title: 'Ordre', icon: <ShoppingBasket /> },
  { path: '/folders', component: Folders, exact: true, title: 'Rabatter', icon: <NewReleases /> },
  { path: '/discounts/:id', component: Discounts },
  { path: '/discount/new', component: NewDiscount, exact: true },
  { path: '/discount/:id', component: DiscountDetails },
  { path: '/renewal', component: RenewalOverview, exact: true, title: 'Fornyelser', icon: <Sync /> },
  { path: '/report', component: ReportOverview, exact: true, title: 'Rapporter', icon: <TrendingDown /> },
  { path: '/subscription', component: SubscriptionOverview, exact: true, title: 'Priser', icon: <LocalOffer /> },
  { path: '/administrasjon', component: Administration, exact: true, title: 'Administrasjon', icon: <Settings /> },
  { path: '/companydetail/:orgNumber', component: CompanyDetail },
];

const App: FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Shell routes={routes} />;
    </LocalizationProvider>
  );
};

export default App;
