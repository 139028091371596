import { TableCell, TableBody, Table, TableRow } from '@mui/material';
import { WidgetPanel } from './WidgetPanel';
import { WidgetTitle } from './WidgetTitle';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { numberAsKr } from '../Formatters';
import { useState } from 'react';

const GET_SALES_SUMMARY = gql`
  query ($monthOffset: Int!) {
    salesSummary: getSalesSummary(monthOffset: $monthOffset) {
      newSales
      totalSales
      addedSales
    }
  }
`;

export function SalesSummary() {
  const [monthOffset, setMonthOffset] = useState(0); // get statistics for current month

  const { data } = useQuery(GET_SALES_SUMMARY, { variables: { monthOffset: monthOffset } });

  return (
    <div>
      <WidgetPanel>
        <WidgetTitle
          title="Salgstall for"
          offset={monthOffset}
          forwardLimit={1}
          yearNav={true}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {!data && <LoadingSpinner />}
        {data && (
          <Table padding="none">
            <TableBody>
              <TableRow>
                <TableCell>Nysalg: </TableCell>
                <TableCell align="right">{numberAsKr(data.salesSummary.newSales)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mersalg: </TableCell>
                <TableCell align="right">{numberAsKr(data.salesSummary.addedSales)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <span style={{ fontWeight: 'bold' }}>Totalt:</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ fontWeight: 'bold' }}>{numberAsKr(data.salesSummary.totalSales)}</span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}

        {/* <LinkToDetails>Se mer</LinkToDetails> */}
      </WidgetPanel>
    </div>
  );
}
