import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_CLIENT_AUTHORIZATION, GET_SINGLE_ADFS_BY_BKS_ID } from './ADFSConfig.graphql';
import { Add, Remove } from '@mui/icons-material';
import { RequiredClaim } from '../../interfaces';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField, FormGroup, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';

const createString = (collection: RequiredClaim[]) => {
  var resultString = '';
  collection &&
    collection.forEach((x) => {
      resultString += x.type + ':' + x.value + '+';
    });
  return resultString;
};
export default function AddClientAuthorizationDialog({ id, onClose, scheme, bksID }: { id: Number; onClose(): void; scheme: string; bksID: any }) {
  const [inputList, setInputList] = useState<RequiredClaim[]>([{ type: '', value: '' }]);
  const [clientName, setClientName] = useState('');
  const handleAddClick = () => {
    setInputList([...inputList, { type: '', value: '' }]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleClose = () => {
    setClientName('');
    onClose();
  };
  const isFormValid = () => {
    return clientName;
  };
  const handleSave = async () => {
    await create();
    handleClose();
  };
  const handleCloseBtn = (event, reason) => {
    if (event.disableBackdropClick && reason === 'backdropClick') {
      return false;
    }
    if (event.disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };
  const [create] = useMutation(CREATE_CLIENT_AUTHORIZATION, {
    variables: {
      input: {
        id: id,
        clientName: clientName,
        claimList: createString(inputList),
        scheme: scheme,
      },
    },
    refetchQueries: [
      {
        query: GET_SINGLE_ADFS_BY_BKS_ID,
        variables: { bksID: bksID && parseInt(bksID) },
      },
    ],
  });

  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={handleCloseBtn} open={true}>
        <DialogTitle>
          Legg til ny Client Authorization
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(1),
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormGroup>
                <TextField
                  label="Client Name"
                  fullWidth
                  value={clientName}
                  type="text"
                  onChange={(event) => setClientName(event.target.value)}
                  margin="normal"
                  name="clientName"
                  required
                />
              </FormGroup>
            </Grid>
          </Grid>
          {inputList.map((x, i) => {
            return (
              <Grid container direction="row" spacing={1} key={i}>
                <Grid item xs={4}>
                  <FormGroup>
                    <TextField
                      label="Claim Type"
                      fullWidth
                      value={x.type}
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      margin="normal"
                      name="type"
                      required
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <TextField
                      label="Claim Value"
                      fullWidth
                      value={x.value}
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      margin="normal"
                      name="value"
                      required
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 50 }}>
                  <Grid container spacing={1} direction="row" style={{ padding: 20 }}>
                    <Grid item xs={2}>
                      {inputList.length !== 1 && (
                        <button onClick={() => handleRemoveClick(i)}>
                          <Remove
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        </button>
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      {inputList.length - 1 === i && (
                        <button onClick={() => handleAddClick()}>
                          <Add
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={() => handleClose()}>
            Lukk
          </Button>
          <Button color="primary" variant="contained" disabled={!isFormValid()} onClick={() => handleSave()}>
            LEGG TIL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
