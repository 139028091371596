import React, { useMemo } from 'react';
import {
  Grid,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  LinearProgress,
  Checkbox,
  Box,
} from '@mui/material';
import { ContactPerson } from './ContactPerson';
import { LicenseAccess as SubscriptionAccess } from './LicenseAccess';
import { UsersInLicense } from './UsersInLicense';
import { ChangeLogCard } from './ChangeLogCard';
import { Orders } from '../../orders/Orders';
import Comments from './Comments';
import Tasks from '../../tasks/overview/Overview';
import { InfoComposite } from './InfoComposite';
import { Discounts } from './Discounts';
import { TestingPanel } from './TestingPanel';
import { Attachments } from './Attachments';
import { Notifications } from './Notifications';
import { useConfig } from '../../components/Configuration';
import { useMutation, useQuery } from '@apollo/client';
import { GET_LICENSE, START_SUSPEND_EMAL, END_SUSPEND_EMAIL, UPDATE_DOC_ACCESS } from './LicenseInfo.graphql';
import { License, LicenseModel, SingleDocumentAccessModel } from '../../interfaces';
import LoadingSpinner from '../../components/LoadingSpinner';
import { MainContentPanel } from '../../components/MainContentPanel';
import { formatDateOnly } from '../../Formatters';
import { GET_ACCESSES, STOP_ACCESSES } from '../../pdf/pdf.graphql';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { GET_GUIDES } from '../../reports/reports.graphql';
import { Guide, GuideSelectionTableProps } from '../../interfaces';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default function Details(props) {
  const config = useConfig();
  const licenseId = Number(props.match.params.id);
  const [suspendLicenseEmailStart, { loading: loadingStart }] = useMutation(START_SUSPEND_EMAL, {
    variables: { input: { id: licenseId, noEmail: false } },
  });
  const [noEmailToUser, { loading: loadingNoEmail }] = useMutation(START_SUSPEND_EMAL, { variables: { input: { id: licenseId, noEmail: true } } });
  const [suspendLicenseEmailEnd, { loading: loadingEnd }] = useMutation(END_SUSPEND_EMAIL, { variables: { input: { id: licenseId } } });
  const { loading, data } = useQuery<{ license: { bksLicense: License; license: LicenseModel; isInvoicable: boolean } }>(GET_LICENSE, {
    variables: { id: licenseId },
  });
  const [selectedMenu, setSelectedMenu] = useState(0);
  if (loading) return <LoadingSpinner />;
  if (!data) return null;
  const license = data.license.license;
  const bksLicense = data.license.bksLicense;
  const isInvoicable = data.license.isInvoicable;
  var disableForm = false;
  if (loadingEnd || loadingStart || loadingNoEmail) disableForm = true;

  const handleChange = (e: any) => {
    if (e.target.value === 0) suspendLicenseEmailEnd();
    else if (e.target.value === 1) suspendLicenseEmailStart();
    else if (e.target.value === 2) noEmailToUser();

    setSelectedMenu(e.target.value);
  };

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item md={12} lg={8}>
        {<InfoComposite license={license} bksLicense={bksLicense} suspendValue={false} />}
      </Grid>
      <Grid item md={12} lg={4}>
        <ContactPerson licenseId={licenseId} />
      </Grid>
      <Grid item md={12} lg={3}>
        <MainContentPanel>
          <FormControl variant="filled">
            <InputLabel id="selected_recipients-label">Epost</InputLabel>
            <Select
              labelId="selected_recipient-filled-label"
              id="selected_recipient-select-filled"
              value={selectedMenu}
              onChange={handleChange}
              disabled={disableForm}>
              <MenuItem value={0}>Vanlig epost</MenuItem>
              <MenuItem value={1}>Utsatt epost, samleliste</MenuItem>
              <MenuItem value={2}>Ingen epost</MenuItem>
            </Select>
          </FormControl>
        </MainContentPanel>
      </Grid>
      <Grid item md={12} lg={5}>
        {<SubscriptionAccess license={license} bksLicense={bksLicense} suspendValue={false} />}
        <DocumentAccess bksLicense={bksLicense} isInvoicable={isInvoicable} />
      </Grid>
      <Grid item md={12} lg={4}>
        <Discounts licenseId={licenseId} />
      </Grid>
      <Grid item xs={12}>
        <Tasks licenseId={licenseId} />
      </Grid>
      <Grid item xs={12}>
        <Orders licenseId={licenseId} />
      </Grid>
      <Grid item xs={12}>
        <Attachments licenseId={licenseId} />
      </Grid>
      <Grid item xs={12}>
        <Comments licenseId={licenseId} />
      </Grid>
      <Grid item xs={6}>
        <Notifications licenseId={licenseId} />
      </Grid>
      <Grid item xs={6}>
        <ChangeLogCard licenseId={licenseId} />
      </Grid>
      {config && config.featureToggleTesting && (
        <Grid item xs={6}>
          <TestingPanel licenseId={licenseId} />
        </Grid>
      )}
      <Grid item xs={12}>
        <UsersInLicense licenseId={licenseId} />
      </Grid>
    </Grid>
  );
}

function DocumentAccess({ bksLicense, isInvoicable }: { bksLicense: License; isInvoicable: boolean }) {
  const { data, loading, refetch } = useQuery<{ accesses: SingleDocumentAccessModel[] }>(GET_ACCESSES, { variables: { licenseId: bksLicense.id } });

  const [stopAccess, { loading: stopAccessLoading }] = useMutation(STOP_ACCESSES);
  let userId: number | undefined;
  //let existingDocuments: number[] | undefined;

  if (!loading && data && data.accesses.length > 0) {
    userId = data.accesses[0].userId;
    //existingDocuments = data.accesses[0].changeModelDocIds;
  } else {
    userId = bksLicense.contactId;
  }
  const onStopAccess = (docId: number) => {
    handleOpenDialog(docId);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);

  const [isDocListDialogOpen, setIsDocListDialogOpen] = useState(false);

  const handleOpenDocListDialog = () => {
    setIsDocListDialogOpen(true);
  };

  const handleCloseDocListDialog = () => {
    setIsDocListDialogOpen(false);
    refetch();
  };

  const handleOpenDialog = (item) => {
    setSelectedListItem(item);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedListItem(null);
    setDialogOpen(false);
  };

  const handleConfirmAction = async () => {
    try {
      await stopAccess({ variables: { input: { licenseId: bksLicense.id, docId: selectedListItem } } });
      handleCloseDialog();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDialogClose = () => {
    handleCloseDocListDialog();
    handleCloseDialog();
  };

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Tilgang til enkeltanvisninger
        <IconButton title="Gi tilgang til enkeltanvisninger" onClick={() => handleOpenDocListDialog()} size="large">
          <Add />
        </IconButton>
      </Typography>
      {loading && <LoadingSpinner />}
      {data && (
        <>
          <List dense>
            {data &&
              data?.accesses &&
              data.accesses.map((access) => (
                <ListItem key={access.id}>
                  <ListItemText
                    primary={`${access.docName} ${access.title}`}
                    secondary={`Fra ${formatDateOnly(access.validFrom)} til ${formatDateOnly(access.validTo)}`}
                  />
                  <ListItemSecondaryAction title="Avslutt tilgangen" onClick={() => onStopAccess(access.docId)}>
                    <IconButton>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          {/* {existingDocuments && existingDocuments?.length > 0 && isInvoicable &&<CreateSingleDocInvoiceButton licenseId={bksLicense.id} />} */}
        </>
      )}
      <Dialog open={isDocListDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          Gi tilgan til enkeltanvisninger
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: (theme) => theme.spacing(1),
              marginBottom: (theme) => theme.spacing(1),
            }}>
            <Button onClick={handleCloseDocListDialog}>Lukke</Button>
          </DialogActions>
        </DialogTitle>

        <DialogContent>
          <DocumentList
            bksLicense={bksLicense}
            userId={userId}
            existingDocument={data?.accesses}
            closeCallback={handleCloseDocListDialog}
            refetchCallback={refetch}
          />
        </DialogContent>
      </Dialog>
      <Dialog key={dialogOpen.toString()} open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Advarsel</DialogTitle>
        <DialogContent>
          <Typography>Er du sikker på at du vil fjerne tilgang?</Typography>
          {stopAccessLoading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Nei</Button>
          <Button onClick={handleConfirmAction} disabled={stopAccessLoading}>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </MainContentPanel>
  );
}

function DocumentList({
  bksLicense,
  userId,
  existingDocument,
  closeCallback,
  refetchCallback,
}: {
  bksLicense: License;
  userId: number | undefined;
  existingDocument: SingleDocumentAccessModel[] | undefined;
  closeCallback: () => void;
  refetchCallback: () => void;
}) {
  const { loading, data } = useQuery<{ guides: Guide[] }>(GET_GUIDES);
  const filteredData = useMemo(() => {
    if (!data || !data.guides || !existingDocument) {
      return [];
    }
    return data.guides.filter((guide) => {
      const exists = existingDocument.some((doc) => doc.docId === guide.docId || doc.docName === guide.docName || doc.title === guide.title);

      return !exists;
    });
  }, [data, existingDocument]);

  return (
    <MainContentPanel>
      {(loading || !data) && <LoadingSpinner />}
      {data && data?.guides && (
        <GuideSelectionTable
          licenseId={bksLicense.id}
          guides={filteredData}
          userId={userId}
          closeCallback={closeCallback}
          retechCallback={refetchCallback}
        />
      )}
    </MainContentPanel>
  );
}

function GuideSelectionTable({ licenseId, guides, userId, closeCallback, retechCallback }: GuideSelectionTableProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getOneYearAfterDate());

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getOneYearAfterDate() {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const [updateDocAccess, { loading: loadingStart }] = useMutation(UPDATE_DOC_ACCESS);
  const [selectedGuides, setSelectedGuides] = useState<number[]>([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGuides = guides?.filter((guide) => {
    const docNameWithoutDot = guide.docName.replace(/\./g, '');
    const searchTermWithoutDot = searchTerm.replace(/\./g, '');

    return (
      docNameWithoutDot.toLowerCase().includes(searchTermWithoutDot.toLowerCase()) || guide.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSave = async () => {
    await updateDocAccess({
      variables: {
        input: {
          licenseId: licenseId,
          newDocIds: selectedGuides,
          userId: userId,
          fromDate: fromDate,
          toDate: toDate,
        },
      },
    });
    closeCallback();
    retechCallback();
  };
  const handleGuideSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, docId: number) => {
    const selected = event.target.checked;
    setSelectedGuides((prevSelectedGuides) => {
      if (selected) {
        return [...prevSelectedGuides, docId]; // Add the selected guide ID
      } else {
        return prevSelectedGuides.filter((id) => id !== docId); // Remove the deselected guide ID
      }
    });
  };
  const isButtonDisabled = selectedGuides.length === 0 || !fromDate || !toDate || fromDate >= toDate;
  return (
    <>
      {loadingStart && <LinearProgress />}
      <Grid container spacing={2}>
        {/* Text fields */}
        <TextField label="Søk" variant="outlined" value={searchTerm} onChange={handleSearchChange} fullWidth margin="normal" />
        <TextField label="Fra dato" type="date" value={fromDate} onChange={handleFromDateChange} fullWidth margin="normal" />
        <TextField label="Til dato" type="date" value={toDate} onChange={handleToDateChange} fullWidth margin="normal" />
      </Grid>
      <Box>
        <div
          style={{
            position: 'sticky',
            top: 0,
          }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#f5f5f5',
                }}>
                <TableCell
                  sx={{
                    width: '10%',
                    textAlign: 'left',
                  }}>
                  Nummer
                </TableCell>
                <TableCell
                  sx={{
                    width: '80%',
                    textAlign: 'left',
                  }}>
                  Tittel
                </TableCell>
                <TableCell
                  sx={{
                    width: '10%',
                    textAlign: 'left',
                  }}>
                  Velg
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div
          style={{
            height: '600px',
            overflow: 'auto',
            minWidth: '650px',
          }}>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableBody>
              {filteredGuides.map((guide) => (
                <TableRow
                  key={guide.docId}
                  sx={{
                    backgroundColor: '#f5f5f5',
                  }}>
                  <TableCell
                    sx={{
                      width: '10%',
                      textAlign: 'left',
                    }}>
                    {guide.docName}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '80%',
                      textAlign: 'left',
                    }}>
                    {guide.title}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '10%',
                      textAlign: 'right',
                    }}>
                    <Checkbox onChange={(event) => handleGuideSelectionChange(event, guide.docId)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Grid
        container
        justifyContent="flex-end"
        spacing={2}
        sx={{
          marginTop: '20px',
        }}>
        {/* Buttons */}
        <Grid item>
          <Button onClick={closeCallback}>Avbryt</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isButtonDisabled}>
            LAGRE
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
