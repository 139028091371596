import { TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { WidgetPanel } from './WidgetPanel';
import { WidgetTitle } from './WidgetTitle';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';

const GET_PENDING_MARKDOWNS = gql`
  query ($monthOffset: Int!) {
    pendingMarkdowns: pendingMarkdownsBySubscriptions(monthOffset: $monthOffset) {
      id
      name
      count
    }
  }
`;

export function PendingMarkdownBySubscription() {
  const [monthOffset, setMonthOffset] = useState(0); // get statistics for current month

  const { loading, data } = useQuery(GET_PENDING_MARKDOWNS, { variables: { monthOffset: monthOffset }, fetchPolicy: 'network-only' });

  return (
    <div>
      <WidgetPanel>
        <WidgetTitle
          title="Tilganger under oppsigelse "
          offset={monthOffset}
          forwardLimit={1}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {loading && <LoadingSpinner />}
        {!loading && !data && <div>Mangler data</div>}
        {data && (
          <div>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell>Abonnement</TableCell>
                  <TableCell align="right">Antall</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.pendingMarkdowns ? (
                  data.pendingMarkdowns.map((x, i) => (
                    <TableRow key={x.id}>
                      <TableCell>{x.name}:</TableCell>
                      <TableCell align="right">{x.count}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell>no data</TableCell>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        {/* <LinkToDetails>Se mer</LinkToDetails> */}
      </WidgetPanel>
    </div>
  );
}
