import React, { FC, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { TopHeader } from './TopHeader';
import ScrollToTop from './ScrollToTop';
import { Box, Toolbar } from '@mui/material';

interface ShellProps {
  routes: RouteDef[];
}

export interface RouteDef {
  path: string;
  component: any;
  exact?: boolean;
  title?: string;
  icon?: React.ReactNode;
}

const drawerWidth = 240;

export function Shell({ routes }: ShellProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>
        <ScrollToTop />
        <TopHeader handleDrawerToggle={handleDrawerToggle} />
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="menu">
          <Sidebar
            routes={routes}
            handleDrawerClose={handleDrawerClose}
            handleDrawerTransitionEnd={handleDrawerTransitionEnd}
            mobileOpen={mobileOpen}
          />
        </Box>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            paddingTop: 0,
            paddingRight: 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}>
          <Toolbar />
          <Switch>
            {routes.map((x, index) => {
              return <Route key={index} exact={x.exact || true} path={x.path} component={x.component} />;
            })}
          </Switch>
        </Box>
      </Box>
    </BrowserRouter>
  );
}
