import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DiscountType } from '../../interfaces.enums';
import { formatDate, formatDateOnly, numberAsKr } from '../../Formatters';
import { Discount } from '../../interfaces';

import { CANCEL_VALUE_UPDATE, GET_SUBSCRIPTIONS } from '../discount.graphql';
import { Delete } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

interface SummaryProps {
  discount: Discount;
}

export function Summary(props: SummaryProps) {
  const { data } = useQuery<any>(GET_SUBSCRIPTIONS);
  if (!data) return null;

  const getSubscriptionName = (id) => {
    if (!data.subscriptions) return '';
    const s = data.subscriptions.find((x) => x.id === id);
    return s ? s.name : '';
  };

  const DiscountListItem = () => {
    const { discount } = props;
    if (!discount.value) return null;
    const ValueText = () => {
      if (discount.type === DiscountType.Percentage) return <span>{discount.value} % på</span>;
      return (
        <>
          <Price discount={discount} />
          <span> pr tilgang på</span>
        </>
      );
    };
    const Product = () => {
      if (discount.subscriptions.filter((x) => x.subscriptionId === -1).length === 1) return <span> alle produkter</span>;
      return <span> {discount.subscriptions.map((x) => getSubscriptionName(x.subscriptionId)).join(', ')}</span>;
    };
    return (
      <li>
        <ValueText />
        <Product />
      </li>
    );
  };

  function Price({ discount }: { discount: Discount }) {
    if (!discount.pendingValueUpdate) return <span>{numberAsKr(discount.value)}</span>;
    return (
      <>
        <Typography sx={{ textDecoration: 'line-through' }} color="error" variant="body1" display="inline">
          {numberAsKr(discount.value)}
        </Typography>
        <Typography color="primary" variant="body1" display="inline">
          {numberAsKr(discount.pendingValueUpdate.value)}
        </Typography>
      </>
    );
  }

  const LimitsListItem = () => {
    const {
      discount: { subscriptions },
    } = props;
    const limitsInSubscriptions = subscriptions.filter((x) => x.numberOfAccesses);
    const allSubscriptions = limitsInSubscriptions.find((x) => x.subscriptionId === -1);
    if (limitsInSubscriptions.length === 0) return null;
    if (allSubscriptions) {
      return <li>Gjelder maks {allSubscriptions.numberOfAccesses} tilganger</li>;
    }
    return (
      <>
        {limitsInSubscriptions.map((x) => (
          <li key={x.subscriptionId}>
            Gjelder maks {x.numberOfAccesses} tilganger til {getSubscriptionName(x.subscriptionId)}
          </li>
        ))}
      </>
    );
  };
  const UsageListItem = () => {
    const {
      discount: { maxUsageCount },
    } = props;
    if (!maxUsageCount) return null;
    return <li>Grense på {maxUsageCount} bruk</li>;
  };
  const RenewalListItem = () => {
    const {
      discount: { allowRenewal },
    } = props;
    if (allowRenewal) return null;
    return <li>Kundeforholdet vil ikke bli fornyet</li>;
  };
  const DatesListItem = () => {
    const {
      discount: { validFrom, validTo },
    } = props;
    const Start = () => (validFrom > new Date() ? <span>{formatDateOnly(validFrom)}</span> : <span>i dag</span>);
    return (
      <li>
        <span>Gjelder fra </span>
        <Start />
        {validTo && <span> til {formatDateOnly(validTo)}</span>}
      </li>
    );
  };
  const AutogeneratedListItem = () => {
    const {
      discount: { emailFilter },
    } = props;
    if (!emailFilter) return null;

    return <li>Autogenerert</li>;
  };
  const EmailFilterListItem = () => {
    const {
      discount: { emailFilter },
    } = props;
    if (!emailFilter) return null;

    return <li>E-post-filter: {emailFilter}</li>;
  };

  const ConfirmUrlActionListItem = () => {
    const {
      discount: { emailFilter, confirmUrlAction },
    } = props;
    if (!emailFilter) return null;

    return <li>Landingsside: {confirmUrlAction === 'register' ? 'Standard' : 'Student'}</li>;
  };

  return (
    <>
      <Typography gutterBottom variant="h6">
        {props.discount.name}
      </Typography>
      <ul>
        <AutogeneratedListItem />
        <EmailFilterListItem />
        <ConfirmUrlActionListItem />
        <DiscountListItem />
        <LimitsListItem />
        {props.discount.onlyFirstYear && <li>Gjelder kun første året</li>}
        <UsageListItem />
        <RenewalListItem />
        <DatesListItem />
      </ul>
      <PendingChange discount={props.discount} />
    </>
  );
}

function PendingChange({ discount }: { discount: Discount }) {
  const [cancelUpdate, { loading }] = useMutation(CANCEL_VALUE_UPDATE, { variables: { input: { discountId: discount.id } } });

  if (!discount.pendingValueUpdate) return null;

  return (
    <>
      <Alert
        sx={{
          display: 'inline-flex',
          verticalAlign: 'middle',
        }}
        severity="info"
        action={
          <IconButton style={{ padding: 5 }} disabled={loading} title="Forkast endring" onClick={() => cancelUpdate()} size="large">
            <Delete />
          </IconButton>
        }>
        Prisen vil bli oppdatert {formatDate(discount.pendingValueUpdate.timeOfChange)}
      </Alert>
    </>
  );
}
