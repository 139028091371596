import { Typography, Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import MoneyIcon from '@mui/icons-material/Money';
import FunctionsIcon from '@mui/icons-material/Functions';
import { LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, Label } from 'recharts';
import { WidgetPanel } from './WidgetPanel';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';

const GET_USER_COUNT_TREND = gql`
  query ($showCount: Boolean!, $showAcc: Boolean!) {
    trend: getUserTrend(showCount: $showCount, showAcc: $showAcc) {
      intervalName
      bfs
      pla
      det
      for
      bvn
    }
  }
`;

export function UserTrend() {
  const [count, setCount] = useState(true);
  const [acc, setAcc] = useState(true);

  const handleChange = (event: React.MouseEvent<HTMLElement>, cnt: boolean) => {
    if (cnt != null) setCount(cnt);
  };

  const { loading, data } = useQuery(GET_USER_COUNT_TREND, { variables: { showCount: count, showAcc: acc }, fetchPolicy: 'network-only' });

  const LinkToDetails = (props) => (
    <Link to={`/report?tab=0&offset=${props.offset}`} title={props.title}>
      {props.children}
    </Link>
  );

  return (
    <div style={{ maxWidth: 800 }}>
      <WidgetPanel>
        <Grid container>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              <span>Brukerutvikling siste 12 måneder</span>
            </Typography>
          </Grid>
        </Grid>

        {loading && <LoadingSpinner />}
        {!loading && !data && <div>Mangler data</div>}
        {data && (
          <div>
            <ResponsiveContainer width="100%" height={310}>
              <LineChart
                width={550}
                height={300}
                data={data.trend}
                margin={{
                  top: 5,
                  right: 10,
                  left: 20,
                  bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="intervalName" />
                <YAxis
                  type="number"
                  name={count ? 'antall' : 'beløp'}
                  width={count ? 60 : 90}
                  tickFormatter={(tick) => (tick ? tick.toLocaleString('nb-NO') : '')}>
                  <Label
                    style={{ fontSize: 14, color: 'grey' }}
                    value={count ? 'antall' : 'beløp'}
                    position="left"
                    angle={-90}
                    offset={!count ? 10 : 0}
                  />
                </YAxis>
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="bfs" stroke="#71B1CC" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="pla" stroke="#52823b" />
                <Line type="monotone" dataKey="det" stroke="#287891" />
                <Line type="monotone" dataKey="for" stroke="#81710a" />
                <Line type="monotone" dataKey="bvn" stroke="#1181C4" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <LinkToDetails>Se mer</LinkToDetails>
          </Grid>
          <Grid item>
            <ToggleButtonGroup size="small" value={count} onChange={handleChange} exclusive>
              <ToggleButton value={true} aria-label="brukere" title="brukere">
                <PeopleIcon></PeopleIcon>
              </ToggleButton>
              <ToggleButton value={false} aria-label="beløp" title="beløp">
                <MoneyIcon></MoneyIcon>
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButton
              sx={{
                verticalAlign: 'baseline',
                marginLeft: 5,
              }}
              size="small"
              value="check"
              selected={acc}
              onChange={() => {
                setAcc(!acc);
              }}
              aria-label="netto/akkumulert"
              title="akkumulert">
              <FunctionsIcon></FunctionsIcon>
            </ToggleButton>
          </Grid>
        </Grid>
      </WidgetPanel>
    </div>
  );
}
