import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { DELETE_ADFS, GET_ADFS } from './ADFSConfig.graphql';
import ConfirmDialog from '../../components/ConfirmDialog';
import { GET_LICENSE } from '../../licenses/details/LicenseInfo.graphql';
import { useMutation } from '@apollo/client';
import { IconButton } from '@mui/material';
interface Prop {
  provider: String;
  licenseId: Number;
}
export default function DeleteAdfs(props: Prop) {
  const { provider, licenseId } = props;

  const handleDelete = () => {
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton sx={{ cursor: 'pointer' }} onClick={() => handleDelete()} title="Avslutt ADFS" size="large">
        <Delete />
      </IconButton>
      {/* <Delete className={classes.clickable} onClick={() => handleDelete()} /> */}
      <DeleteDialog open={open} onClose={onClose} name={provider} licenseId={licenseId} />
    </>
  );
  function DeleteDialog({ open, onClose, name, licenseId }) {
    const [remove] = useMutation<any>(DELETE_ADFS, {
      variables: {
        input: {
          adfsScheme: name,
          bksID: licenseId,
        },
      },
      refetchQueries: [
        {
          query: GET_LICENSE,
          variables: { id: licenseId },
        },
        {
          query: GET_ADFS,
        },
      ],
    });
    const onSave = async () => {
      await remove();
      onClose(false);
    };
    let msg = 'Vil du slette ' + name;
    return (
      <ConfirmDialog
        title="Slett adfs"
        message={msg}
        open={open}
        confirm={onSave}
        deny={onClose}
        confirmText="Slett"
        DialogProps={{ fullWidth: true, maxWidth: 'xs' }}
      />
    );
  }
}
