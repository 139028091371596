import React from 'react';
import { Typography, FormControlLabel, Switch, Table, TableHead, TableRow, TableCell, IconButton } from '@mui/material';
import { MainContentPanel } from '../../components/MainContentPanel';
import Loading from '../../components/LoadingSpinner';

import { GET_DISCOUNTS } from '../discount.graphql';
import { SearchField } from '../../components/SearchField';
import { useState } from 'react';
import Fuse from 'fuse.js';
import { formatDateOnly, numberAsKr, numberAsPercentage } from '../../Formatters';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { DiscountOverview } from '../../interfaces';
import { Counter } from '../../components/Counter';
import { DiscountType } from '../../interfaces.enums';
import { SortDirection } from '@mui/material/TableCell';
import { compareFn, TableCellSort } from '../../components/TableComponents';
import moment from 'moment';
import { useQuery } from '@apollo/client';

export function Discounts(props) {
  const id = props.match.params.id as string;
  const queryTask = useQuery<{ discounts: DiscountOverview[] }>(GET_DISCOUNTS, { variables: { id } });

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        <span>Rabatter</span>
        <Link to="/discount/new">
          <IconButton size="large">
            <Add />
          </IconButton>
        </Link>
      </Typography>
      <Content task={queryTask} />
    </MainContentPanel>
  );
}

function Content({ task }) {
  const { loading, error, data } = task;
  if (loading) return <Loading />;
  if (error) return <Typography>{error}</Typography>;

  return <DiscountSearchableTable discounts={data.discounts} />;
}

function DiscountSearchableTable({ discounts }: { discounts: DiscountOverview[] }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [onlyActive, setOnlyActive] = useState(false);
  const filteredDiscounts = filterDiscounts(discounts, searchTerm, onlyActive);
  return (
    <div>
      <SearchField filter={setSearchTerm} />
      <FormControlLabel control={<Switch onChange={() => setOnlyActive(!onlyActive)} checked={onlyActive} />} label="Kun ledige" />
      <Counter list={filteredDiscounts} />
      <DiscountTable discounts={filteredDiscounts} />
    </div>
  );
}

function filterDiscounts(discounts: DiscountOverview[], searchTerm: string, onlyActive: boolean) {
  let semiFiltered = onlyActive ? discounts.filter((x) => x.isApplicable) : discounts;
  var options = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    location: 0,
    distance: 10,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['name', 'emailFilter'],
  };
  if (!searchTerm) {
    return semiFiltered;
  } else {
    var fuse = new Fuse(semiFiltered, options);
    var filtered = fuse.search(searchTerm);
    return filtered;
  }
}

interface DiscountTableProps {
  discounts: DiscountOverview[];
}

function DiscountTable({ discounts }: DiscountTableProps) {
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [sortOrder, setSortOrder] = useState<SortDirection>(false);
  const [orderBy, setOrderBy] = useState<string>('Navn');

  const changeSort = (header: string) => {
    if (orderBy === header) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      return;
    }
    setOrderBy(header);
    setSortOrder('asc');
  };

  const getSorter = () => {
    const fn = (getter: (x: DiscountOverview) => any) => compareFn<DiscountOverview>(getter, sortOrder);

    if (orderBy === 'Navn') return fn((x) => x.name);
    if (orderBy === 'Type') return fn((x) => x.type);
    if (orderBy === 'Rabatt') return fn((x) => x.value);
    if (orderBy === 'Gyldig fra') return fn((x) => moment(x.validFrom));
    if (orderBy === 'Gyldig til') return fn((x) => (x.validTo ? moment(x.validTo) : null));
    if (orderBy === 'E-post-filter') return fn((x) => x.emailFilter);
    if (orderBy === 'Brukt av') return fn((x) => x.usages);

    return (): number => 0;
  };
  const TableHeader = ({ children }) => (
    <TableCellSort active={orderBy === children} onClick={() => changeSort(children)} sortDirection={sortOrder}>
      {children}
    </TableCellSort>
  );

  const discountsForSort = [...discounts];

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableHeader>Navn</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Rabatt</TableHeader>
            <TableHeader>Gyldig fra</TableHeader>
            <TableHeader>Gyldig til</TableHeader>
            <TableHeader>E-post-filter</TableHeader>
            <TableHeader>Brukt av</TableHeader>
          </TableRow>
        </TableHead>
        <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={discountsForSort.length > numberOfItems}>
          {discountsForSort
            .sort(getSorter())
            .slice(0, numberOfItems)
            .map((x) => {
              return (
                <TableRow key={x.id}>
                  <TableCell>
                    <Link to={'/discount/' + x.id}>{x.name}</Link>
                  </TableCell>
                  <TableCell>{x.type === DiscountType.Percentage ? 'Prosent' : 'Fastpris'}</TableCell>
                  <TableCell>{x.type === DiscountType.Percentage ? numberAsPercentage(x.value) : numberAsKr(x.value)}</TableCell>
                  <TableCell>{formatDateOnly(x.validFrom)}</TableCell>
                  <TableCell>{formatDateOnly(x.validTo)}</TableCell>
                  <TableCell>{x.emailFilter}</TableCell>
                  <TableCell>{x.usages}</TableCell>
                </TableRow>
              );
            })}
        </InfiniteScroll>
      </Table>
    </>
  );
}
