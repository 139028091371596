import React, { FC } from 'react';
import { Paper } from '@mui/material';

export const WidgetPanel: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Paper
      sx={{
        paddingLeft: (theme) => theme.spacing(theme.breakpoints.up('sm') ? 3 : 2),
        paddingRight: (theme) => theme.spacing(theme.breakpoints.up('sm') ? 3 : 2),
        paddingTop: (theme) => theme.spacing(2),
        paddingBottom: (theme) => theme.spacing(2),
        marginRight: (theme) => theme.spacing(2),
        marginTop: (theme) => theme.spacing(2),
      }}>
      {children}
    </Paper>
  );
};
