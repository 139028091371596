import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LICENSE_BY_GUIDE } from '../reports.graphql';
import { Table, TableHead, TableRow, TableCell, Typography, Paper, IconButton, Link, Grid2, Stack } from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner';
import UseLogTable from './UseLogTable';
import { TimeRange } from '../churn';
import Grid from '@mui/material/Grid';
import XLSX from 'xlsx';
import { ArrowBack } from '@mui/icons-material';
import ExcelLogo from '../../assets/microsoft-excel.svg?react';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface DownloadModel {
  count: number;
  type: string;
}
interface CustomerModel {
  customerId: number;
  name: string;
  count: number;
  email: string;
  phoneNumber: string;
  downloads: DownloadModel[];
}
export default function CustomerTable(props) {
  const { guideId, handleBack, selectedGuide } = props;
  const [from, setFrom] = useState<moment.Moment | null>(moment().startOf('month'));
  const [to, setTo] = useState<moment.Moment | null>(null);
  let [customerId, setCustomerId] = useState(0);
  let [isCustomerVisible, setIsCustomerVisible] = useState(true);
  let [customerName, setCustomerName] = useState();
  let [email, setEmail] = useState();
  const handleCustomerClick = (id, name, email) => {
    setCustomerId(id);
    setCustomerName(name);
    setIsCustomerVisible(false);
    setEmail(email);
  };
  const backToCustomerList = () => {
    setIsCustomerVisible(true);
  };
  const handleClick = () => {
    handleBack();
  };
  const generateOrderedKey = (email) => {
    const randomNumber = Math.floor(Math.random() * Math.floor(100000));
    return `${email}_${new Date().getTime()}_${randomNumber}`;
  };
  const renderBreadCrumb = () => {
    return (
      <>
        <Grid2 container direction="row" spacing={2} alignItems="center">
          <Grid2>
            <Typography gutterBottom variant="subtitle1">
              <IconButton onClick={() => handleClick()} title="Go bak til anvisning liste" size="large">
                <ArrowBack />
              </IconButton>
            </Typography>{' '}
          </Grid2>
          <Grid2>
            <Typography variant="body2" gutterBottom>
              Anvisning:{selectedGuide}
            </Typography>
          </Grid2>
          <Grid2>{data && <CustomerTableExcelExport model={data.customers} />}</Grid2>
        </Grid2>
        <Stack direction="row" spacing={2}>
          <DatePicker disableFuture label="Fra dato" value={from} onChange={setFrom} />
          <DatePicker disableFuture label="Til dato" value={to} onChange={setTo} />
        </Stack>
      </>
    );
  };
  const renderTable = () => {
    return (
      <>
        {loading && <LoadingSpinner />}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Epost kontakt</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Antall søk</TableCell>
              <TableCell>Nedlastinger</TableCell>
            </TableRow>
          </TableHead>
          {data &&
            data.customers.map((c) => {
              return (
                <TableRow key={generateOrderedKey(c.email)}>
                  <TableCell>
                    <Link component="button" onClick={() => handleCustomerClick(c.customerId, c.name, c.email)}>
                      {c.name}
                    </Link>
                  </TableCell>
                  <TableCell>{c.email}</TableCell>
                  <TableCell>{c.phoneNumber}</TableCell>
                  <TableCell>{c.count}</TableCell>
                  <TableCell>{FormatDownloads(c.downloads)}</TableCell>
                </TableRow>
              );
            })}
        </Table>
      </>
    );
  };
  const { loading, data } = useQuery<{ customers: CustomerModel[] }>(GET_LICENSE_BY_GUIDE, {
    variables: { guideId: guideId, fromDate: from, toDate: to },
    fetchPolicy: 'network-only',
  });
  if (isCustomerVisible) {
    return (
      <Stack spacing={2}>
        {renderBreadCrumb()}
        {renderTable()}
      </Stack>
    );
  } else {
    return (
      <UseLogTable
        selectedGuide={selectedGuide}
        email={email}
        customerName={customerName}
        customerId={customerId}
        guideId={guideId}
        fromDate={from}
        toDate={to}
        callback={() => backToCustomerList()}
      />
    );
  }
}
const FormatDownloads = (downloads) => {
  let result = '';
  downloads.forEach((download) => {
    if (downloads[downloads.length - 1] === download) {
      result = result.concat(download.type, '(', download.count, ')');
    } else {
      result = result.concat(download.type, '(', download.count, ')', ',');
    }
  });
  return result;
};
function CustomerTableExcelExport({ model }: { model: CustomerModel[] }) {
  const exportFile = () => {
    const workbook = XLSX.utils.book_new();
    const heading: string[] = ['Kunde', 'Epost kontakt', 'Telefon', 'Antall søk', 'Nedlastinger'];
    const workBookName = 'Kunde antall søk';
    const data = model.map((x) => [x.name, x.email, x.phoneNumber, x.count, FormatDownloads(x.downloads)]);
    const input = [heading, ...data];
    const sheet = XLSX.utils.aoa_to_sheet(input);
    XLSX.utils.book_append_sheet(workbook, sheet, workBookName);
    XLSX.writeFile(workbook, 'kundesøk.xlsx');
  };
  return (
    <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
      <ExcelLogo />
    </IconButton>
  );
}
